import React, { useState } from 'react';
import Select from '../../../components/forms/Select/Select';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { Button } from '../../../components/forms/Buttons/Button';

export default function SelectStudentPart({ uuid }) {
  const [gradeList, setGradeList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [comment, setComment] = useState('');

  return (
    <div className='md:m-0 mx-4 md:h-[calc(100vh-16rem)] flex flex-col justify-center shadow-md rounded-md border'>
      {false && (
        <div className='flex flex-col mt-28 gap-4 text-center'>
          <p className='mb-12'>
            <span className='text-red-600'>*</span>Each student can have only
            one individualized roadmap.
          </p>

          {/* <label></label> */}
          <Select label='Select grade' options={gradeList} />
          <Select label='Select student' options={studentList} />
        </div>
      )}
      {true && (
        <div className='py-8 px-16'>
          <dl className='divide-y divide-gray-100'>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Student's main information
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                <div className='grid grid-cols-6 gap-2'>
                  <div className='col-span-1'>
                    <div className='font-semibold'>Name</div>
                    <div>Dias</div>
                  </div>
                  <div className='col-span-5'>
                    <div className='font-semibold'>Surname</div>
                    <div>Orisbek</div>
                  </div>
                  <div className='col-span-2'>
                    <div className='font-semibold'>Hobby</div>
                    <div>Football, Volleyball, Basketball </div>
                  </div>
                  <div className='col-span-4'>
                    <div className='font-semibold'>Olympiad</div>
                    <div>Matematik olympiad</div>
                  </div>
                </div>
              </dd>
            </div>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Exam Results
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                <div className='flex flex-row gap-8'>
                  <div className=''>
                    <div className='font-semibold'>BTS 1</div>
                    <div>30/40</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>BTS 1</div>
                    <div>30/40</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>BTS 1</div>
                    <div>30/40</div>
                  </div>
                  <div className=''>
                    <div className='font-semibold'>BTS 1</div>
                    <div>30/40</div>
                  </div>
                </div>
              </dd>
            </div>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Additional information
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                <TextArea
                  // label={t('accounting.comment')}
                  rows={6}
                  className='border rounded-lg shadow-md'
                  placeholder={'jkhkjhjk'}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </dd>
            </div>
          </dl>
        </div>
      )}
      <div className='flex flex-row justify-end px-6 pb-8'>
        <Button text='Next'></Button>
      </div>
    </div>
  );
}
