import React, { useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';

export default function SurveyPart() {
  // Array to hold selected answers for each question
  const [answers, setAnswers] = useState(Array(3).fill(null));

  // Function to handle answer selection
  const handleAnswerChange = (questionIndex, answer) => {
    const updatedAnswers = [...answers];
    updatedAnswers[questionIndex] = answer;
    setAnswers(updatedAnswers);
  };

  return (
    <div className='p-12 w-full shadow-md rounded-md border'>
      {/* Question 1 */}
      <div className='flex flex-col'>
        <div className='mb-6'>
          <p className='mb-2 font-semibold'>What is the capital of France?</p>
          <div className='flex flex-col'>
            {['Paris', 'London', 'Rome', 'Berlin'].map((option, index) => (
              <label key={index} className='mb-1'>
                <input
                  type='radio'
                  name='question1'
                  value={option}
                  checked={answers[0] === option}
                  onChange={() => handleAnswerChange(0, option)}
                  className='mr-2'
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        {/* Question 2 */}
        <div className='mb-6'>
          <p className='mb-2 font-semibold'>
            What is the largest planet in our solar system?
          </p>
          <div className='flex flex-col'>
            {['Earth', 'Mars', 'Jupiter', 'Saturn'].map((option, index) => (
              <label key={index} className='mb-1'>
                <input
                  type='radio'
                  name='question2'
                  value={option}
                  checked={answers[1] === option}
                  onChange={() => handleAnswerChange(1, option)}
                  className='mr-2'
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        {/* Question 3 */}
        <div className='mb-6'>
          <p className='mb-2 font-semibold'>
            Which element has the chemical symbol 'O'?
          </p>
          <div className='flex flex-col'>
            {['Oxygen', 'Gold', 'Hydrogen', 'Nitrogen'].map((option, index) => (
              <label key={index} className='mb-1'>
                <input
                  type='radio'
                  name='question3'
                  value={option}
                  checked={answers[2] === option}
                  onChange={() => handleAnswerChange(2, option)}
                  className='mr-2'
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        {/* Display Answers */}
        <div className='mt-4'>
          <h2 className='text-xl font-semibold'>Your Answers:</h2>
          <ul className='list-disc ml-5 mt-2'>
            {answers.map((answer, index) => (
              <li key={index}>
                Question {index + 1}: {answer}
              </li>
            ))}
          </ul>
        </div>
        <div className='flex flex-row justify-end px-6'>
          <Button text='Next'></Button>
        </div>
      </div>
    </div>
  );
}
