import { PencilIcon } from '@heroicons/react/24/outline';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/InputBil';
import { toast } from 'react-toastify';
import DatePicker from '../../../components/forms/DatePicker/DatePicker';
import {
  deleteRoadmapSubtask,
  patchRoadmapSubtask,
} from '../../../services/roadmapTask/roadmapSubtaskService';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { format } from 'date-fns';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';
import SidebarModal from '../../../components/modals/SidebarModal/SidebarModal';

const EditSub = ({ datum, refreshData, taskStatus, className }) => {
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [link, setLink] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDeleteConfirmation = () => {
    toast.dark(
      <>
        <div>{t('roadmap.are_you_sure')}</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            text={t('buttons.yes')}
            color='error-solid'
            onClick={() => {
              setLoadingDelete(true);
              deleteRoadmapSubtask(datum?.uuid)
                .then(() => {
                  toast.success(t('roadmap.deleted_successfully'));
                  refreshData();
                  onClose();
                })
                .finally(() => setLoadingDelete(false));
            }}
          />
          <Button
            text={t('buttons.no')}
            className='text-white'
            onClick={toast.dismiss}
          />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  const reset = useCallback(() => {
    setTitle(datum?.name);
    setDescription(datum?.description);
    setLink(datum?.link);
    setStartDate(new Date(datum?.start_date));
    setEndDate(new Date(datum?.deadline));
  }, [
    datum?.deadline,
    datum?.description,
    datum?.link,
    datum?.name,
    datum?.start_date,
  ]);

  const onClose = () => {
    reset();
    setId('');
  };

  useEffect(() => {
    if ((datum && title === null) || id !== uuid) {
      reset();
    }
  }, [datum, id, reset, title, uuid]);

  return (
    <>
      <div
        className={className}
        onClick={() => {
          setId(uuid);
        }}
      >
        {datum?.name}
      </div>
      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.edit')}
        HeaderIcon={PencilIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title ?? ''}
            required
          />
          <TextArea
            label={t('projects.description')}
            value={description ?? ''}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Input
            label={t('roadmap.link')}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            value={link ?? ''}
          />
          <div className='flex flex-row gap-4'>
            <DatePicker
              date={startDate ? new Date(startDate) : null}
              setDate={(d) => {
                // if (endDate < d) {
                //   toast.error(t('roadmap.reversed_date_error'));
                //   return;
                // }
                setStartDate(d);
              }}
              label={t('roadmap.start_date')}
              required
            />
            <DatePicker
              date={endDate ? new Date(endDate) : null}
              setDate={(d) => {
                // if (startDate > d) {
                //   toast.error(t('roadmap.reversed_date_error'));
                //   return;
                // }
                setEndDate(d);
              }}
              label={t('roadmap.end_date')}
              required
            />
          </div>

          <div className='flex flex-row justify-center gap-4 mt-4'>
            <Button
              color='error'
              text={t('roadmap.delete')}
              loading={loadingDelete}
              onClick={() => {
                handleDeleteConfirmation();
              }}
              disabled={taskStatus === 'finished'}
            />
            <Button
              text={t('roadmap.save')}
              loading={loadingSubmit}
              onClick={() => {
                if (!title || !startDate || !endDate) {
                  toast.error(t('roadmap.required_fields_empty'));
                  return;
                }

                if (startDate > endDate) {
                  toast.error(t('roadmap.reversed_date_error'));
                  return;
                }

                const body = new FormData();
                body.append('name', title);
                body.append('description', description);
                body.append('link', link);
                body.append(
                  'start_date',
                  format(new Date(startDate), 'yyyy-MM-dd'),
                );
                body.append(
                  'deadline',
                  format(new Date(endDate), 'yyyy-MM-dd'),
                );

                setLoadingSubmit(true);
                patchRoadmapSubtask(datum?.uuid, body)
                  .then(() => {
                    toast.success(t('roadmap.added_successfully'));
                    refreshData();
                    onClose();
                  })
                  .catch((err) => {
                    toast.error(
                      err?.response?.data?.link?.length
                        ? err?.response?.data?.link[0]
                        : t('roadmap.something_went_wrong'),
                    );
                  })
                  .finally(() => setLoadingSubmit(false));
              }}
              disabled={taskStatus === 'finished'}
            />
          </div>
        </div>
      </SidebarModal>
    </>
  );
};

export default EditSub;
