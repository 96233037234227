import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/forms/Buttons/Button';
import roadmapImg from '../../../assets/images/roadmap_card.png';

export default function RoadmapCard() {
  const navigate = useNavigate();

  return (
    <div className='w-full rounded-xl overflow-hidden shadow-md flex flex-col justify-between col-span-3'>
      <div className='flex md:flex-row flex-col-reverse text-lg'>
        <div className='flex flex-col justify-between px-6 py-4 overflow-hidden md:w-[30%] w-full'>
          <div>
            <div className='font-bold text-2xl mb-2 whitespace-pre-line'>
              {'Chart and track your school year'}
            </div>
            <p className='min-h-fit whitespace-pre-line'>
              {
                'Make a plan for the school year and track your progress in timeline chart. You can create or generate tasks with subtasks together with mentors. Each task customizable by you or your mentor.'
              }
            </p>
            {/* <div className='flex flex-col gap-3 mt-8'>
              <span className='flex flex-row items-center gap-1'>
                <div className='h-6 w-6 rounded bg-green-500' />
                {` - Academic Domain`}
              </span>
              <span className='flex flex-row items-center gap-1'>
                <div className='h-6 w-6 rounded bg-violet-500' />
                {` - Career Domain`}
              </span>
              <span className='flex flex-row items-center gap-1'>
                <div className='h-6 w-6 rounded bg-blue-500' />
                {` - Social Emitional Domain`}
              </span>
            </div> */}
          </div>
          <div className='py-2'>
            <Button
              text={'Explore'}
              onClick={() => {
                navigate('/roadmap', {});
              }}
            />
          </div>
        </div>

        <img
          className='md:w-[70%] w-full h-fit p-2 rounded'
          src={roadmapImg}
          alt='cap'
        />
      </div>
    </div>
  );
}
