/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  ArrowDownTrayIcon,
  ArrowSmallUpIcon,
  CheckCircleIcon,
  ClockIcon,
  DocumentIcon,
  PlayIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '../../../components/forms/Buttons/Button';
import { patchProjectSubmission } from '../../../services/projects/projectSubmissionService';
import NewSubmission from './NewSubmission';
import avatarPlaceholder from '../../../assets/images/avatar_placeholder.jpg';
import { UserContext } from '../../../utils/contexts/UserContext';
import CheckSubmission from './CheckSubmission';
import moment from 'moment';
import { getSubmissionReviews } from '../../../services/projects/projectSubmissionReviewService';
import EditSubmission from './EditSubmission';

const Submission = ({ projectAssignment, submissions, refreshData }) => {
  const [t] = useTranslation();
  const { uuid } = useParams();
  const { user } = useContext(UserContext);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingChecking, setLoadingChecking] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [editing, setEditing] = useState();

  const getSubmissionsReviewData = () => {
    const promises = [];
    for (let s of submissions) {
      promises.push(
        getSubmissionReviews({ project_submission: s?.uuid })
          .then((res) => res?.results)
          .catch(() => {}),
      );
    }
    Promise.all(promises).then((res) => {
      setReviews(res.flat(1));
    });
  };

  useEffect(() => {
    console.log('projectAssignment', projectAssignment);
  }, [projectAssignment]);

  useEffect(() => {
    if (submissions?.length) {
      getSubmissionsReviewData();
    }
  }, [submissions]);

  const downloadFile = (s) => {
    if (s?.file1) {
      fetch(s?.file1, {
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          const filenameParts = s?.file1.split('/');
          const filename = filenameParts[filenameParts?.length - 1];
          link.setAttribute('download', filename);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    }
    if (s?.file2) {
      fetch(s?.file2, {
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          const filenameParts = s?.file2.split('/');
          const filename = filenameParts[filenameParts?.length - 1];
          link.setAttribute('download', filename);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    }
  };

  return (
    <div className='w-full md:py-8 lg:py-8 py-4 md:px-4 lg:px-4 flex flex-col gap-8'>
      {user?.roles?.some((r) => r?.name === 'STUDENT') && editing ? (
        <EditSubmission
          submission={editing}
          refreshData={refreshData}
          setEditing={setEditing}
        />
      ) : user?.roles?.some((r) => r?.name === 'PROJECTREVIEWER') &&
        submissions.some((s) => s?.is_checking) ? (
        <CheckSubmission
          submission={submissions.find((s) => s?.is_checking)}
          refreshData={refreshData}
        />
      ) : (
        submissions.map((s) => (
          <div
            key={s?.uuid}
            className='flex flex-col lg:gap-4 md:gap-4 gap-2 border-2 shadow-sm 
              md:p-4 lg:p-4 p-3 rounded-lg'
          >
            <div className='flex flex-row justify-between items-center'>
              <div className='flex flex-row gap-4 items-center'>
                {s?.evaluation === 1 ? (
                  <div className='text-success flex flex-row items-center gap-2'>
                    {t('projects.accepted')}
                    <CheckCircleIcon className='h-6' />
                  </div>
                ) : s?.evaluation === 0 ? (
                  <div className='text-error flex flex-row items-center gap-2'>
                    {t('projects.declined')}
                    <XCircleIcon className='h-6' />
                  </div>
                ) : s?.is_checking ? (
                  <div className='text-primary flex flex-row items-center gap-2'>
                    {t('projects.checking')}
                    <ClockIcon className='h-6' />
                  </div>
                ) : (
                  <div className='text-error flex flex-row items-center gap-2'>
                    {t('projects.submitted')}
                    <ClockIcon className='h-6' />
                  </div>
                )}
                <div className='text-secondary'>
                  {moment(s?.updated).format('MMM DD, yyyy')}
                </div>
              </div>
              <div className='flex flex-row gap-2'>
                <Button
                  color='primary'
                  text={t('projects.download')}
                  Icon={ArrowDownTrayIcon}
                  onClick={() => downloadFile(s)}
                />
                {user?.roles?.some((r) => r?.name === 'STUDENT') &&
                  s?.evaluation === null && (
                    <Button
                      text={t('projects.resubmit')}
                      Icon={ArrowSmallUpIcon}
                      color='success'
                      onClick={() => {
                        setEditing(s);
                      }}
                      disabled={s?.is_checking}
                    />
                  )}
                {user?.roles?.some((r) => r?.name === 'PROJECTREVIEWER') &&
                  s?.evaluation === null &&
                  !s?.is_checking && (
                    <Button
                      text={t('projects.start_checking')}
                      loading={loadingChecking}
                      Icon={PlayIcon}
                      color='success'
                      onClick={() => {
                        const body = new FormData();
                        body.append('is_checking', true);

                        setLoadingChecking(true);

                        patchProjectSubmission(s?.uuid, body)
                          .then((res) => {
                            refreshData();
                          })
                          .catch(() => {})
                          .finally(() => setLoadingChecking(false));
                      }}
                    />
                  )}
              </div>
            </div>
            <div className='flex flex-row md:gap-4 lg:gap-4 gap-2'>
              <div className='flex flex-row gap-2'>
                {s?.file1 && (
                  <div
                    className='border-2 rounded-lg flex flex-col gap-2 items-center 
                    md:p-4 lg:p-4 p-2'
                  >
                    <DocumentIcon className='md:h-16 lg:h-16 h-10 text-secondary' />
                    <div>{t('projects.file_1')}</div>
                  </div>
                )}
                {s?.file2 && (
                  <div className='border-2 rounded-lg flex flex-col gap-2 items-center p-4'>
                    <DocumentIcon className='h-16 text-secondary' />
                    <div>{t('projects.file_2')}</div>
                  </div>
                )}
              </div>
              <div className='flex flex-row gap-4 items-center border-2 rounded-lg p-4 w-full'>
                <div className='shrink-0'>
                  <img
                    src={
                      s?.project_assignment?.student?.profile_picture ||
                      avatarPlaceholder
                    }
                    alt='avatar'
                    className='md:w-20 md:h-20 lg:w-20 lg:h-20 w-16 h-16
                      rounded-full object-cover'
                  />
                </div>
                <div className='flex flex-col gap-2'>
                  <div className='font-medium '>
                    <Link
                      to={`/profile/${s?.project_assignment?.student?.uuid}`}
                    >
                      <span className='text-primary hover:underline cursor-pointer'>{`${s?.project_assignment?.student?.first_name} ${s?.project_assignment?.student?.last_name}`}</span>{' '}
                    </Link>
                    {t('projects.messaged')}:
                  </div>

                  <div>
                    {s?.comment === 'undefined'
                      ? t('projects.no_comment')
                      : s?.comment}
                  </div>
                </div>
              </div>
            </div>
            {reviews?.some((r) => r?.project_submission === s?.uuid) && (
              <div className='flex flex-row gap-4 items-center border-2 rounded-lg p-4 w-full'>
                <div className='shrink-0'>
                  <img
                    src={
                      reviews.find((r) => r?.project_submission === s?.uuid)
                        ?.evaluated_by?.profile_picture || avatarPlaceholder
                    }
                    alt='avatar'
                    className='md:w-20 md:h-20 lg:w-20 lg:h-20 w-16 h-16 
                      rounded-full object-cover'
                  />
                </div>
                <div className='flex flex-col gap-2'>
                  <div className='font-medium'>
                    {t('projects.reviewer')}{' '}
                    <span className='text-primary'>{`${
                      reviews.find((r) => r?.project_submission === s?.uuid)
                        ?.evaluated_by?.first_name ?? ''
                    } ${
                      reviews.find((r) => r?.project_submission === s?.uuid)
                        ?.evaluated_by?.last_name ?? ''
                    }`}</span>{' '}
                    {t('projects.messaged')}:
                  </div>
                  <div>
                    {reviews.find((r) => r?.project_submission === s?.uuid)
                      ?.comment === 'undefined'
                      ? t('projects.no_comment')
                      : reviews.find((r) => r?.project_submission === s?.uuid)
                          ?.comment}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))
      )}
      {user?.roles?.some((r) => r?.name === 'STUDENT') &&
        !submissions.some(
          (s) => s?.evaluation === null || s?.evaluation === 1,
        ) && (
          <NewSubmission
            projectAssignment={projectAssignment}
            refreshData={refreshData}
          />
        )}
    </div>
  );
};

export default Submission;
