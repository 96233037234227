import { LinkIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import React from 'react';

const Admission = ({ info }) => {
  return (
    <div className='flex flex-row gap-4'>
      <div className='w-2/3 flex flex-col gap-4 border rounded-xl p-4'>
        <div className='flex flex-row gap-4 items-center'>
          <div className='text-xl font-semibold'>
            {info?.admission?.genTitle}
          </div>
          {!!info?.importantDateLink && (
            <LinkIcon
              className='h-5 text-primary cursor-pointer hover:text-blue-700'
              onClick={() => window.open(info?.importantDateLink, '_blank')}
            />
          )}
        </div>
        {info?.admission?.admissionSteps?.map((s) => (
          <div key={s?.index} className='flex flex-row gap-4 items-center'>
            <div className='text-primary text-5xl'>{s?.index}</div>
            <div className='flex flex-col'>
              <div className='font-semibold'>{s?.name}</div>
              <div>
                {s?.description
                  ?.replace(/(<([^>]+)>)/gi, '')
                  ?.replace('&nbsp;', ' ')}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='w-1/3 flex flex-col gap-4 '>
        {info?.importantDates?.length ? (
          <div className='flex flex-col gap-4 border rounded-xl p-4'>
            <div className='text-lg font-semibold text-primary'>
              {'Важные даты'}
            </div>
            <div className='flex flex-col gap-4'>
              {info?.importantDates?.map((d) => (
                <div key={d?.id} className='flex flex-col gap-2'>
                  <div className='font-semibold'>{d?.name}</div>
                  <div className='flex flex-col gap-2'>
                    {d?.additionalDates?.map((ad) => (
                      <div
                        key={ad?.index}
                        className='pl-4 flex flex-row gap-8 items-center justify-between'
                      >
                        <div>{ad?.description}</div>
                        <div className='text-gray-500'>
                          {format(new Date(ad?.date), 'd/MM/yyyy')}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : undefined}
        <div className='flex flex-col gap-4 border rounded-xl p-4'>
          <div className='text-lg font-semibold text-primary'>
            {info?.admission?.reqTitle}
          </div>
          <div className='flex flex-col divide-y'>
            <div className='flex flex-col gap-1 py-2'>
              {info?.admission?.certificates?.map((s) => (
                <div
                  key={s?.certificateType}
                  className='flex flex-row justify-between font-light'
                >
                  <div>{s?.certificateType}</div>
                  <div>{s?.grade}</div>
                </div>
              ))}
            </div>
            {info?.admission?.requirements?.map((r) => (
              <div key={r?.title} className='flex flex-col gap-2 py-2'>
                <div className='text-lg font-semibold'>{r?.title}</div>
                <div className='flex flex-col gap-2'>
                  {r?.fields?.map((f) => (
                    <div key={f?.name} className=''>
                      {f?.name}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admission;
