import React, { useCallback, useContext, useEffect, useState } from 'react';
import Description from './Description';
import Roadmap from './Roadmap';
import MyTasksIcon from '../../components/Icons/MyTasks';
import MyRoadmapIcon from '../../components/Icons/MyRoadmap';
import MyRoadmap from './MyRoadmap/MyRoadmap';
import RoadmapList from './RoadmapList/RoadmapList';
import { UserContext } from '../../utils/contexts/UserContext';
import { getRoadmaps } from '../../services/roadmapTask/roadmapService';

export default function RoadmapMain({ studentUuid }) {
  const { user } = useContext(UserContext);
  const isTeacher = user?.roles?.some((r) => r.name === 'PROJECTADMIN');
  const [tab, setTab] = useState(0);
  const [roadmap, setRoadmap] = useState();

  const getRoadmapData = useCallback(() => {
    getRoadmaps({
      student: studentUuid ?? user?.uuid,
    })
      .then((res) => {
        setRoadmap(res?.results[0]);
      })
      .catch(() => {});
    //   .finally(() => setLoading(false));
  }, [studentUuid, user?.uuid]);

  useEffect(() => {
    getRoadmapData();
  }, [getRoadmapData]);

  return (
    <div className='flex flex-col gap-4 p-4 h-full'>
      <Description />
      <div className='flex flex-row h-full'>
        <div className='rounded-2xl border w-1/5 py-4 h-full'>
          <div
            className={`flex flex-row border-l-2 items-center gap-1 px-4 p-2 text-lg hover:bg-slate-100 ${
              tab === 0
                ? 'bg-slate-100 border-current text-primary'
                : 'border-transparent cursor-pointer'
            }`}
            onClick={() => setTab(0)}
          >
            <MyTasksIcon className='fill-current h-6' />
            My Tasks
          </div>
          {(isTeacher || studentUuid || roadmap) && (
            <div
              className={`flex flex-row border-l-2 items-center gap-1 px-4 p-2 text-lg hover:bg-slate-100 ${
                tab === 1
                  ? 'bg-slate-100 border-current text-primary'
                  : 'border-transparent cursor-pointer'
              }`}
              onClick={() => setTab(1)}
            >
              <MyRoadmapIcon className='fill-current h-6' />
              My Roadmap
            </div>
          )}
        </div>
        <div className='overflow-scroll w-full'>
          {tab === 0 && <Roadmap studentUuid={studentUuid} />}
          {tab === 1 &&
            (isTeacher && !studentUuid ? (
              <RoadmapList />
            ) : (
              <MyRoadmap roadmap={roadmap} />
            ))}
        </div>
      </div>
    </div>
  );
}
