import React, { useContext, useEffect, useState } from 'react';
import { getColorForProjectLevel } from '../../../utils/helpers/helpers';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../utils/contexts/UserContext';
import Pagination from '../../../components/Pagination/Pagination';
import { getProjectSubmissions } from '../../../services/submissions/projectSubmissionService';
import { CheckIcon, ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import Select from '../../../components/forms/Select/Select';
import { useTranslation } from 'react-i18next';
import { getOrganizations } from '../../../services/catalogs/organizationService';
import Title from '../Title';

const PAGE_SIZE = 20;

export default function AdminSubmissions() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  // const [isChecking, setIsChecking] = useState(null);

  const onPageChange = async (pg) => {
    setLoading(true);
    await getProjectSubmissions({
      uuid: null,
      organization: selectedSchool,
      page: pg,
    })
      .then((res) => {
        setSubmissions(res.results);
        setCount(res?.count);
      })
      .finally(() => {
        setLoading(false);
      });
    setPage(pg);
  };

  const updateTable = (school) => {
    setLoading(true);
    getProjectSubmissions({ uuid: null, organization: school })
      .then((res) => {
        setSubmissions(res?.results);
        setCount(res?.count);
        setPage(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user?.uuid) {
      setLoading(true);
      getProjectSubmissions({ uuid: null, organization: null })
        .then((res) => {
          setSubmissions(res?.results);
          setCount(res?.count);
        })
        .finally(() => {
          setLoading(false);
        });

      getOrganizations().then((res) => {
        setSchools(res.results);
      });
    }

    return () => {};
  }, [user]);

  const getStatus = (check, evaluate) => {
    if (check === false && evaluate === null) {
      return {
        status: 'Waiting',
        statusColor: 'text-orange-600',
        projectStatus: 'Not checked',
        statusIcon: 'clock',
      };
    }
    if (check === false && evaluate === 0) {
      return {
        status: 'Declined',
        statusColor: 'text-red-600',
        projectStatus: 'Declined',
        statusIcon: 'xmark',
      };
    }
    if (check === false && evaluate === 1) {
      return {
        status: 'Accepted',
        statusColor: 'text-green-600',
        projectStatus: 'Accepted',
        statusIcon: 'check',
      };
    }
    if (check === true && evaluate === null) {
      return {
        status: 'Checking',
        statusColor: 'text-blue-600',
        projectStatus: 'Not checked',
        statusIcon: 'clock',
      };
    }
  };

  return (
    <div className='w-full flex flex-col border rounded-md mt-8'>
      <Title />
      {loading ? (
        <SimpleLoader className='h-12 my-4' />
      ) : (
        <div className='flex flex-col gap-4'>
          {schools.length > 1 && (
            <Select
              options={[
                { label: t('stats.all'), value: null },
                ...schools.map((d) => ({ label: d?.name, value: d?.uuid })),
              ]}
              onChange={(e) => {
                updateTable(e.value);
                setSelectedSchool(e?.value);
              }}
              loading={loading}
              value={selectedSchool}
              className='w-full lg:w-96 ml-2 mt-2'
            />
          )}
          <table className='table-auto w-full'>
            <thead>
              <tr className='border-y border-gray-200'>
                <th className='px-4 py-2 text-center text-gray-500 font-normal'>
                  Status
                </th>
                <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                  Student
                </th>
                <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                  School
                </th>
                <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                  Project
                </th>
                {/* <th className='px-4 py-2 text-left text-gray-500 font-normal border-r'>
              Status
            </th> */}
                <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                  Date
                </th>
                <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                  Subject
                </th>
                <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                  Difficulty
                </th>
                <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                  Reviewer
                </th>
              </tr>
            </thead>
            <tbody>
              {submissions?.map((item, i) => (
                <tr
                  key={i}
                  className='border-b border-gray-200 even:bg-gray-100 cursor-pointer hover:bg-gray-300 text-sm'
                  onClick={() => {
                    navigate(`/submissions/${item?.uuid}`);
                  }}
                >
                  <td
                    className={`px-4 py-2 text-center flex items-center ${
                      getStatus(item?.is_checking, item?.evaluation)
                        ?.statusColor
                    }`}
                  >
                    {getStatus(item?.is_checking, item?.evaluation)
                      ?.statusIcon === 'check' && (
                      <CheckIcon className='h-4 pr-2 inline-block' />
                    )}
                    {getStatus(item?.is_checking, item?.evaluation)
                      ?.statusIcon === 'clock' && (
                      <ClockIcon className='h-4 pr-2 inline-block' />
                    )}
                    {getStatus(item?.is_checking, item?.evaluation)
                      ?.statusIcon === 'xmark' && (
                      <XMarkIcon className='h-4 pr-2 inline-block' />
                    )}

                    <span>
                      {getStatus(item?.is_checking, item?.evaluation)?.status}
                    </span>
                  </td>
                  <td className='px-4 py-2 text-left'>
                    {item?.project_assignment?.student?.first_name +
                      ' ' +
                      item?.project_assignment?.student?.last_name}
                  </td>
                  <td className='px-4 py-2 text-left'>
                    {item?.project_assignment?.organization}
                  </td>
                  <td className='px-4 py-2 text-left font-semibold'>
                    {item?.project_assignment?.project?.name}
                  </td>
                  {/* <td className='px-4 py-2 text-left text-blue-600'>
                {item?.student?.project_assignment?.project?.name}
              </td> */}
                  <td className='px-4 py-2 text-left'>
                    {moment(item?.updated).format('MMM DD, yyyy')}
                  </td>
                  <td className='px-4 py-2 text-left'>
                    {item?.project_assignment?.project?.skill?.uuid
                      ? item?.project_assignment?.project?.skill?.name
                      : '-'}
                  </td>

                  <td
                    className={`px-4 py-2 text-center  ${getColorForProjectLevel(
                      item?.project_assignment?.project?.level,
                    )}`}
                  >
                    {item?.project_assignment?.project?.level
                      ? item?.project_assignment?.project?.level
                      : '-'}
                  </td>
                  <td className='px-4 py-2 text-left'>
                    {item?.project_assignment?.mentor?.uuid
                      ? item?.project_assignment?.mentor?.first_name +
                        ' ' +
                        item?.project_assignment?.mentor?.last_name
                      : 'No reviewer'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        totalPages={count / PAGE_SIZE}
        count={count}
      />
    </div>
  );
}
