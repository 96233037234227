import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import { getRoadmaps } from '../../../services/roadmapTask/roadmapService';
import { PlusIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PlayIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const RoadmapList = ({ studentUuid }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [t] = useTranslation();
  const [roadmaps, setRoadmaps] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const getRoadmapData = useCallback(() => {
    setLoading(true);
    getRoadmaps({
      student: studentUuid ?? user?.uuid,
    })
      .then((res) => {
        setRoadmaps(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [studentUuid, user?.uuid]);

  useEffect(() => {
    getRoadmapData();
  }, [getRoadmapData]);

  const colors = [
    'from-red-500 to-red-200',
    'from-violet-600 to-violet-200',
    'from-green-400 to-blue-400',
  ];

  return (
    <div className='flex flex-row flex-wrap gap-4 p-4'>
      <div
        className='relative w-72 h-48 rounded-xl border-4 cursor-pointer
      flex flex-col justify-center text-lg items-center font-bold
      '
        onClick={() => navigate(`/roadmap/create`)}
      >
        <PlusIcon className='h-12 text-amethyst' />
        <div className='bg-gradient-to-r from-amethyst to-error bg-clip-text text-transparent'>
          {t('studyplans.create_new_plan')}
        </div>
      </div>
      {roadmaps?.map((roadmap, i) => {
        return (
          <div
            key={roadmap.uuid}
            className={`relative w-72 h-48 rounded-xl border-4 cursor-pointer
            flex flex-col justify-between text-lgfont-bold bg-gradient-to-tr ${
              colors[i % (colors?.length - 1)]
            }`}
          >
            <div className='flex flex-col h-full justify-between p-4 '>
              <div className='flex flex-col'>
                <span className='text-white text-2xl font-bold'>
                  {roadmap?.student?.first_name} {roadmap?.student?.last_name}
                </span>
                <span className='text-gray-200 text-sm font-bold'>
                  {roadmap.name}
                </span>
              </div>
              <span className='text-sm text-gray-200'>
                Author:{' '}
                {`${roadmap.assignor?.first_name} ${roadmap.assignor?.last_name}`}
              </span>
            </div>
            <div className='bg-white flex flex-row justify-between p-1 px-2'>
              <div className='flex flex-row gap-4 '>
                <div className='flex flex-col items-center -space-y-2'>
                  <span className='text-lg font-semibold'>
                    {moment(roadmap.end_date, 'YYYY-MM-DD')?.diff(
                      moment(roadmap.start_date, 'YYYY-MM-DD'),
                      'week',
                    )}
                  </span>
                  <span className='text-gray-500 text-sm'>Weeks</span>
                </div>
                <div className='flex flex-col items-center -space-y-2'>
                  <span className='text-lg font-semibold'>
                    {roadmap.task_count}
                  </span>
                  <span className='text-gray-500 text-sm'>Tasks</span>
                </div>
              </div>

              <div>
                <div className='flex flex-col justify-center'>
                  <PlayIcon className='h-9 rounded-full border p-1.5 hover:bg-slate-200' />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RoadmapList;
