import React from 'react';
import { useNavigate } from 'react-router-dom';
import mountainImg from '../../../assets/images/tests/header-mountains.svg';
import interaction1Img from '../../../assets/images/tests/interaction-1.svg';
import interaction2Img from '../../../assets/images/tests/interaction-2.svg';
import vectorImg from '../../../assets/images/tests/Vector1.svg';
import brainImg from '../../../assets/images/tests/mis 1.svg';
import poster1Img from '../../../assets/images/tests/1669702782744 1.svg';
import poster2Img from '../../../assets/images/tests/1669702782744 2.svg';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';

const MBTI_UUID = '1f538f13-782d-46f2-9431-e979dd62d00d';
const MIS_UUID = '4ea7d806-b604-46a6-bb2c-736606fc304f';

const Description = ({ test }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <div className='flex flex-col gap-4'>
      {test === MBTI_UUID ? (
        <div className='flex flex-col md:gap-4 gap-8'>
          <div className='flex flex-col'>
            <div className='bg-[#4298B4] flex flex-col justify-center items-center gap-2 p-4'>
              <div className='text-white flex flex-col justify-center items-center gap-2'>
                <p className='text-center text-3xl font-semibold leading-[1.1] md:leading-normal'>{`“It’s so incredible to finally be understood.”`}</p>
                <p className='text-center text-xl md:w-3/4 lg:w-2/3 leading-[1.1] md:leading-normal'>
                  {`Only 10 minutes to get a “freakishly accurate” description of who you are and why you do things the way you do.`}
                </p>
              </div>
              <div>
                <button
                  className='bg-[#813BE2] text-white rounded-lg px-4 py-3 font-semibold text-xl
                    hover:bg-white hover:text-[#813BE2]'
                  onClick={() =>
                    navigate({
                      pathname: `/tests/${MBTI_UUID}`,
                    })
                  }
                >
                  <div className='flex flex-row items-center gap-1'>
                    <p>{'Take the test'}</p>
                    <ChevronRightIcon className='h-5' />
                  </div>
                </button>
              </div>
            </div>
            <div>
              <img src={mountainImg} alt='img' className='w-full -mt-1' />
            </div>
          </div>
          <div className='flex md:flex-row flex-col justify-between items-center'>
            <div className='flex flex-col md:w-1/2 justify-center lg:px-12 md:px-4 gap-2'>
              <div className='font-bold text-2xl'>
                Understand better yourself
              </div>
              <div className='text-xl leading-relaxed'>{`Imagine gaining a deeper understanding of your strengths and weaknesses, your communication style, and what motivates you. The MBTI test helps you discover your unique personality type among 16 distinct profiles. This isn't just another quiz - it's a tool to improve your personal and professional life.`}</div>
            </div>
            <div className='md:w-1/2'>
              <img src={interaction1Img} alt='img' className='w-full' />
            </div>
          </div>
          <div className='flex md:flex-row flex-col-reverse justify-between items-center'>
            <div className='md:w-1/2'>
              <img src={interaction2Img} alt='img' className='w-full' />
            </div>
            <div className='flex flex-col md:w-1/2 justify-center lg:px-12 md:px-4 gap-2'>
              <div className='font-bold text-2xl'>
                Define your future career
              </div>
              <div className='text-xl leading-relaxed'>{`Understanding your personality, interests, and values is the first step to identifying a career that suits you. Tools like the MBTI test can provide insights into your natural preferences and help you find a career where you'll thrive.`}</div>
            </div>
          </div>
        </div>
      ) : test === MIS_UUID ? (
        <div className='flex flex-col gap-8'>
          <div
            className='flex flex-row px-8 pt-8 pb-20'
            style={{
              backgroundImage: `url(${vectorImg})`,
              backgroundPosition: 'bottom',
              backgroundSize: 'cover',
            }}
          >
            <div className='flex flex-col justify-center items-center gap-2 p-4 md:w-3/4'>
              <div className='flex flex-col justify-center gap-4'>
                <p className='text-3xl font-semibold'>{`Unlock your unique genius: take the MIS test!`}</p>
                <p className='text-xl md:w-3/4 lg:w-2/3'>
                  {`Have you ever wondered why some people excel in music, while others shine in mathematics or social interactions? The MIS test reveals the diverse ways in which you are intelligent, helping you understand and harness your unique strengths.`}
                </p>

                <div>
                  <button
                    className='bg-[#813BE2] text-white rounded-lg px-4 py-3 font-semibold text-xl
                hover:bg-white hover:text-[#813BE2]'
                    onClick={() =>
                      navigate({
                        pathname: `/tests/${MIS_UUID}`,
                      })
                    }
                  >
                    <div className='flex flex-row items-center gap-1'>
                      <p>{'Take the test'}</p>
                      <ChevronRightIcon className='h-5' />
                    </div>
                  </button>
                </div>
              </div>
            </div>
            {!isMobile && (
              <div className='w-1/4 flex flex-row justify-center items-center'>
                <img src={brainImg} alt='img' className='h-2/3' />
              </div>
            )}
          </div>
          <div className='flex md:flex-row flex-col justify-between items-center'>
            <div className='flex flex-col md:w-1/2 justify-center lg:px-12 md:px-4 gap-2'>
              <div className='font-bold text-2xl'>
                Why you should take the MIS
              </div>
              <div className='text-xl leading-relaxed'>{`Based on Howard Gardner's groundbreaking theory, this test explores 8 distinct types of intelligence. It's not about labeling you; it's about empowering you to leverage your natural abilities to achieve your full potential. `}</div>
            </div>
            <div className='md:w-1/2'>
              <img src={poster1Img} alt='img' className='w-full' />
            </div>
          </div>
          <div className='flex md:flex-row flex-col-reverse justify-between items-center'>
            <div className='md:w-1/2'>
              <img src={poster2Img} alt='img' className='w-full' />
            </div>
            <div className='flex flex-col md:w-1/2 justify-center lg:px-12 md:px-4 gap-2'>
              <div className='font-bold text-2xl'>
                Benefits of understanding your MIS
              </div>
              <div className='text-xl leading-relaxed whitespace-pre-line'>{`- Tailor your study methods to suit your strongest intelligences 
- Find jobs that utilize your talents, leading to greater job satisfaction and success.
- Communicate better with others by understanding their MIS
- Focus on all areas of intelligence to become a well-rounded individual`}</div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Description;
