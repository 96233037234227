import React, { useContext, useEffect, useState } from 'react';
import Select from '../../components/forms/Select/Select';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../utils/contexts/UserContext';
import {
  getGeneralStats,
  getOrganizationsStats,
  getOverallStatusStats,
} from '../../services/stats/statsService';
import { Tab } from '@headlessui/react';
import ProjectStats from './ProjectStats';
import GeneralStats from './GeneralStats';
import { classNames } from '../../utils/helpers/helpers';
import Title from './Title';

export default function Stats() {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generalStats, setGeneralStats] = useState([]);
  const [projectStats, setProjectStats] = useState([]);
  const [overall, setOverall] = useState([]);

  useEffect(() => {
    if (user?.uuid && projectStats?.length === 1) {
      setSelectedSchool(user?.organization);
    }
  }, [user, projectStats]);

  useEffect(() => {
    setLoading(true);
    getOrganizationsStats({ page_size: 80 })
      .then((res) => {
        // console.log('organizationdata', res?.results);
        setProjectStats(res?.results);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setSelectedSchool('all');
      });

    getOverallStatusStats({})
      .then((res) => {
        // console.log('overall', res?.result);
        setOverall(res?.result);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (selectedSchool) {
      getGeneralStats({
        organization: selectedSchool === 'all' ? null : selectedSchool,
      })
        .then((res) => {
          setGeneralStats(res?.result);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setSelectedSchool(selectedSchool);
        });
    }

    return () => {};
  }, [selectedSchool]);

  return (
    <div className='flex flex-col gap-4 p-4'>
      <Title />
      <Select
        options={[
          { label: t('All schools'), value: 'all' },
          ...projectStats.map((d) => ({ label: d?.name, value: d?.uuid })),
        ]}
        onChange={(e) => {
          // console.log(e);
          setSelectedSchool(e?.value);
        }}
        loading={loading}
        value={selectedSchool}
        className='w-full lg:w-96'
      />
      <Tab.Group manual>
        <Tab.List className='flex flex-row gap-2'>
          <Tab
            className={({ selected }) =>
              classNames(
                'px-4 py-2 rounded-md',
                'bg-blue-100',
                selected
                  ? 'bg-blue-600 text-white'
                  : 'text-blue-600 hover:bg-blue-600 hover:text-white',
              )
            }
          >
            General
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'px-4 py-2 rounded-md',
                'bg-blue-100',
                selected
                  ? 'bg-blue-600 text-white'
                  : 'text-blue-600 hover:bg-blue-600 hover:text-white',
              )
            }
          >
            Projects
          </Tab>
        </Tab.List>
        <Tab.Panels className='border rounded-md'>
          <Tab.Panel>
            <GeneralStats
              school={selectedSchool}
              data={generalStats}
              user={user}
            />
          </Tab.Panel>
          <Tab.Panel>
            <ProjectStats
              school={selectedSchool}
              overall={overall}
              data={projectStats}
              user={user}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
