import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getSkills } from '../../services/projects/skillsService';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { Button } from '../../components/forms/Buttons/Button';

export default function TableOfStudents({
  gradeStats,
  school,
  showStatsForSchool,
  grade,
}) {
  const [t] = useTranslation();

  const [loadingData, setLoadingData] = useState(false);
  const [skills, setSkills] = useState([]);
  const [headers] = useState([]);

  useEffect(() => {
    setLoadingData(true);

    if (gradeStats?.length) {
    }
    getSkills({})
      .then((res) => {
        // console.log('skills', res?.results);
        setSkills(res?.results);
        for (let r of gradeStats) {
          for (let k of Object.keys(r).filter(
            (x) => x.slice(0, 6) === 'skill_',
          )) {
            if (!headers.includes(k)) {
              headers.push(k);
            }
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoadingData(false);
      });
  }, [gradeStats, headers]);

  return (
    <div className='flex flex-col gap-4 mt-4'>
      <div className='flex flex-row gap-2 items-center'>
        <Button
          className='max-h-8'
          text={t('Back')}
          onClick={() => {
            setLoadingData(true);
            showStatsForSchool(school, school?.name);
          }}
        />
        <div className='text-xl font-bold'>{`${t('Grade')}: ${
          grade?.name
        }`}</div>
      </div>
      {loadingData ? (
        <div className='flex flex-col p-8 items-center'>
          <SimpleLoader className={'h-12 w-12'} />
        </div>
      ) : (
        <div className='border-2 rounded-xl p-2'>
          <table className='table-auto w-full'>
            <thead>
              <tr className='border-b-2'>
                <th className='pb-2 px-2'>#</th>
                <th className='text-left'>{t('stats.student')}</th>
                <th className='text-left'>{t('stats.school')}</th>
                <th>{t('studyplans.projects')}</th>
                {headers.map((h) => (
                  <th key={h} className='last:pr-2'>
                    {skills?.find((s) => s?.uuid === h.slice(6))?.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {gradeStats.map((d, index) => (
                <tr
                  key={index}
                  className='border-b-2 last:border-b-0 even:bg-gray-100'
                >
                  <td className='py-1 px-2 text-center'>{index + 1}</td>
                  <td>
                    <Link
                      to={`/profile/${d?.uuid}`}
                      className='hover:underline cursor-pointer'
                    >
                      {`${d?.first_name} ${d?.last_name}`}
                    </Link>
                  </td>
                  <td>{d?.organization?.name}</td>
                  <td className='text-center'>{d?.projects}</td>
                  {headers.map((h) => (
                    <td key={h} className='last:pr-2 text-center'>
                      {Object.keys(d).includes(h) ? d[h] : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
