import React, { useContext, useState } from 'react';
import SurveyPart from './SurveyPart';
import EditPart from './EditPart';
import { Tab } from '@headlessui/react';
import ApprovePart from './ApprovePart';
import SelectStudentPart from './SelectStudentPart';
import { UserContext } from '../../../utils/contexts/UserContext';

export default function CreateRoadmap() {
  const { user } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const steps = [
    {
      number: 1,
      name: 'Survey',
      panel: <SelectStudentPart uuid={user?.uuid} />,
    },
    { number: 2, name: 'Survey', panel: <SurveyPart /> },
    { number: 3, name: 'Edit', panel: <EditPart /> },
    { number: 4, name: 'Approve', panel: <ApprovePart /> },
  ];

  return (
    <div>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className='flex flex-row justify-center gap-32 my-8'>
          {steps.map((step, index) => (
            <div key={step.number} className='flex items-center'>
              <Tab>
                <div className='relative flex flex-col items-center'>
                  <div className='w-12 h-12 rounded-full bg-blue-500 text-white flex items-center justify-center text-lg z-10'>
                    {step.number}
                  </div>
                  <p className='mt-2 text-center'>{step.name}</p>
                  {index !== steps.length - 1 && (
                    <div className='absolute top-6 -right-36 w-40 h-[0.125rem] bg-red-300'></div>
                  )}
                </div>
              </Tab>
            </div>
          ))}
        </Tab.List>
        <Tab.Panels>
          {steps.map((step, index) => (
            <Tab.Panel className='' key={index}>
              {step.panel}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
