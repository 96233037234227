import React from 'react';

const COLORS = [
  'bg-green-500',
  'bg-yellow-500',
  'bg-red-500',
  'bg-orange-500',
  'bg-purple-500',
  'bg-brown-500',
  'bg-pink-500',
  'bg-sky-500',
];

const Cost = ({ info }) => {
  return (
    <div className='flex md:flex-row lg:flex-row flex-col gap-8 border rounded-lg p-8'>
      {info?.discount?.length ? (
        <div className='lg:w-3/5 md:w-3/5 w-full border rounded-lg p-4 flex flex-col gap-8'>
          <div className='text-2xl font-medium -mb-4'>Скидки и стипендии</div>
          {info?.discount?.map((d) => (
            <div className='flex flex-col gap-4'>
              <div className='text-xl font-medium -mb-2'>{d?.title}</div>
              {!!d?.covered?.length && (
                <div className='flex flex-col'>
                  <div className='font-medium text-lg border-b mb-1'>
                    Что покрывает стипендия?
                  </div>
                  {d?.covered?.map((c) => (
                    <div>{c?.name}</div>
                  ))}
                </div>
              )}
              {!!d?.requirements?.length && (
                <div className='flex flex-col'>
                  <div className='font-medium text-lg border-b mb-1'>
                    Требования для получения стипендии
                  </div>
                  {d?.requirements?.map((r) => (
                    <div>{r?.name}</div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className='lg:w-3/5 md:w-3/5 w-full border rounded-lg p-4 text-center'>
          <div className='text-gray-400 text-xl italic mt-4'>
            Информация по скидкам отсутсвует
          </div>
        </div>
      )}
      <div
        className='lg:w-2/5 md:w-2/5 w-full flex flex-col gap-4 p-4
        border rounded-lg'
      >
        <div className='flex flex-col w-full gap-4 justify-between items-center'>
          <div className='flex flex-col gap-2 justify-center items-center'>
            <div className='text-2xl text-gray-500'>Общая стоимость</div>
            <div className='text-5xl'>{`${
              info?.tuitionCost?.tuitionCost +
              info?.tuitionCost?.additionalCost?.reduce(
                (acc, val) =>
                  acc + (isNaN(val?.cost) ? 0 : parseInt(val?.cost)),
                0,
              )
            }$`}</div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row gap-2 items-center'>
                <div className='h-3 w-3 rounded-full bg-primary'></div>
                <div>Стоимость обучения</div>
              </div>
              <div className='text-primary'>{`${info?.tuitionCost?.tuitionCost}$`}</div>
            </div>
            {info?.tuitionCost?.additionalCost?.map((ac, index) => (
              <div key={ac?.name} className='flex flex-row justify-between'>
                <div className='flex flex-row gap-2 items-center'>
                  <div
                    className={`h-3 w-3 rounded-full ${COLORS[index]}`}
                  ></div>
                  <div>{ac?.name}</div>
                </div>
                <div>{`${ac?.cost}$`}</div>
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-row w-full'>
          <div
            className='h-3 bg-primary rounded-l-md'
            style={{
              width: `${
                (info?.tuitionCost?.tuitionCost /
                  (info?.tuitionCost?.tuitionCost +
                    info?.tuitionCost?.additionalCost?.reduce(
                      (acc, val) =>
                        acc + (isNaN(val?.cost) ? 0 : parseInt(val?.cost)),
                      0,
                    ))) *
                100
              }%`,
            }}
          ></div>
          {info?.tuitionCost?.additionalCost?.map((ac, index) => (
            <div
              key={index}
              className={`h-3 ${COLORS[index]} last:rounded-r-md`}
              style={{
                width: isNaN(ac?.cost)
                  ? '0%'
                  : `${
                      (parseInt(ac?.cost) /
                        (info?.tuitionCost?.tuitionCost +
                          info?.tuitionCost?.additionalCost?.reduce(
                            (acc, val) =>
                              acc +
                              (isNaN(val?.cost) ? 0 : parseInt(val?.cost)),
                            0,
                          ))) *
                      100
                    }%`,
              }}
            ></div>
          ))}
        </div>
      </div>
      {info?.scholarships?.programCost?.length ? (
        <div className='flex flex-col gap-4 px-4'>
          <div className='font-semibold text-xl -mb-2'>
            {info?.scholarships?.title}
          </div>
          {info?.scholarships?.programCost?.map((pc) => (
            <div
              key={pc?.title}
              className='flex flex-col gap-2 border-t-2 pt-2'
            >
              <div className='text-lg font-medium'>{pc?.title}</div>
              {pc?.fields?.map((f) => (
                <div
                  key={f?.name}
                  className='pl-4 flex flex-row justify-between'
                >
                  <div>{f?.name}</div>
                  <div className='font-semibold'>
                    {!f?.cost || isNaN(f?.cost) ? f?.cost : `${f?.cost}$`}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : undefined}
    </div>
  );
};

export default Cost;
