import React from 'react';

const MyTasksIcon = ({ className }) => {
  return (
    <svg
      className={className ? className : 'h-5'}
      viewBox='0 0 24 24'
      fill='current'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.87012 3H7.37012C8.61276 3 9.62012 4.00736 9.62012 5.25V8.75C9.62012 9.99264 8.61276 11 7.37012 11H3.87012C2.62748 11 1.62012 9.99264 1.62012 8.75V5.25C1.62012 4.00736 2.62748 3 3.87012 3ZM7.37012 9.5C7.78433 9.5 8.12012 9.16421 8.12012 8.75V5.25C8.12012 4.83579 7.78433 4.5 7.37012 4.5H3.87012C3.45816 4.50539 3.1255 4.83804 3.12012 5.25V8.75C3.1255 9.16196 3.45816 9.49461 3.87012 9.5H7.37012Z'
        fill='current'
      />
      <path
        d='M13.6201 6.75H18.6201C19.0343 6.75 19.3701 6.41421 19.3701 6C19.3701 5.58579 19.0343 5.25 18.6201 5.25H13.6201C13.2059 5.25 12.8701 5.58579 12.8701 6C12.8701 6.41421 13.2059 6.75 13.6201 6.75Z'
        fill='current'
      />
      <path
        d='M13.6201 9.75H21.6201C22.0343 9.75 22.3701 9.41421 22.3701 9C22.3701 8.58579 22.0343 8.25 21.6201 8.25H13.6201C13.2059 8.25 12.8701 8.58579 12.8701 9C12.8701 9.41421 13.2059 9.75 13.6201 9.75Z'
        fill='current'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.87012 13H7.37012C8.61276 13 9.62012 14.0074 9.62012 15.25V18.75C9.62012 19.3467 9.38306 19.919 8.96111 20.341C8.53915 20.7629 7.96685 21 7.37012 21H3.87012C2.62748 21 1.62012 19.9926 1.62012 18.75V15.25C1.62012 14.0074 2.62748 13 3.87012 13ZM7.37012 19.5C7.78433 19.5 8.12012 19.1642 8.12012 18.75V15.25C8.12012 14.8358 7.78433 14.5 7.37012 14.5H3.87012C3.45816 14.5054 3.1255 14.838 3.12012 15.25V18.75C3.1255 19.162 3.45816 19.4946 3.87012 19.5H7.37012Z'
        fill='current'
      />
      <path
        d='M13.6201 16.75H18.6201C19.0343 16.75 19.3701 16.4142 19.3701 16C19.3701 15.5858 19.0343 15.25 18.6201 15.25H13.6201C13.2059 15.25 12.8701 15.5858 12.8701 16C12.8701 16.4142 13.2059 16.75 13.6201 16.75Z'
        fill='current'
      />
      <path
        d='M21.6201 18.25H13.6201C13.2059 18.25 12.8701 18.5858 12.8701 19C12.8701 19.4142 13.2059 19.75 13.6201 19.75H21.6201C22.0343 19.75 22.3701 19.4142 22.3701 19C22.3701 18.5858 22.0343 18.25 21.6201 18.25Z'
        fill='current'
      />
    </svg>
  );
};
export default MyTasksIcon;
