import React, { useEffect, useState } from 'react';
import { getSkillPoint } from '../../services/projects/skillsService';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';

export default function AcademicRankCard({ studentUuid, isInProfile }) {
  const [firstLevelSkills, setFirstLevelSkills] = useState([]);
  const [pairForChart, setPairForChart] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (studentUuid) {
      setLoading(true);
      getSkillPoint({ uuid: null, user_id: studentUuid, level: 1 })
        .then((res) => {
          const updatedResults = res?.results?.map((s) => ({
            ...s,
            pair_to_show:
              s?.childs?.find((obj) => obj?.is_prefered === true) ??
              s?.childs?.reduce(
                (max, obj) => (obj.point > max.point ? obj : max),
                s?.childs[0],
              ),
          }));
          setFirstLevelSkills(updatedResults);

          const allPairs = res?.results?.flatMap((skill) =>
            skill?.childs.map((p) => ({ ...p, domain: skill.name })),
          );
          const preferredPair = allPairs.find((p) => p?.is_prefered === true);
          setPairForChart(preferredPair);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {};
  }, [studentUuid]);

  return (
    <div className='text-left bg-white border rounded-md shadow-md py-2 pr-4'>
      <h2 className='font-semibold text-lg pl-4'>Academic Rank</h2>
      <div className='flex flex-col md:flex-row justify-between items-center md:items-start'>
        {/* {pairForChart?.uuid ? ( */}
        <div className='relative'>
          <div className='absolute inset-0 flex items-center justify-center'>
            <div className='w-20 h-20 text-2xl font-medium mb-8 text-center text-gray-600 flex flex-col justify-center'>
              <p>{pairForChart?.uuid ? `${pairForChart?.point}%` : ''}</p>
            </div>
          </div>
          <ReactApexChart
            options={{
              chart: {
                height: 80,
                type: 'radialBar',
                parentHeightOffset: 0,
              },
              stroke: {
                lineCap: 'round',
              },
              fill: { colors: '#fb923c' },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '54%',
                  },
                  dataLabels: {
                    show: true,
                    formatter: function (val) {
                      // console.log('val', val);
                      return val?.series ?? '';
                    },
                    name: { fontSize: '0px', color: '#fb923c' },
                    value: { fontSize: '0px', color: '#000000' },
                  },
                },
              },
              labels: [
                { name: pairForChart?.name, series: pairForChart?.point },
              ],
              legend: {
                show: true,
                showForSingleSeries: true,
                position: 'bottom',
                fontSize: '14px',
                fontWeight: 550,
                offsetY: -20,
                formatter: function (seriesName) {
                  if (seriesName?.name) {
                    return [seriesName?.name];
                  } else {
                    return [];
                  }
                },
                markers: {
                  width: 0,
                },
                onItemClick: {
                  toggleDataSeries: false,
                },
              },
              noData: {
                text: 'no data',
              },
            }}
            series={[pairForChart?.point ?? '']}
            type='radialBar'
            height={240}
          />
        </div>
        {/* ) : (
          <div className='pl-16 text-center italic'> no data</div>
        )} */}

        <div className='flex flex-col 2xl:w-2/3 w-3/5 mt-8 md:mt-0'>
          {loading === false
            ? // firstLevelSkills?.length === 0 ? (
              firstLevelSkills?.map((fls) => (
                <div
                  key={fls?.uuid}
                  className=' px-4 py-2 whitespace-nowrap w-full flex flex-row'
                >
                  <div></div>
                  <div className='w-full'>
                    <div className='flex flex-row justify-between gap-2 text-sm'>
                      {`${fls?.name} (${fls?.pair_to_show?.name})`}
                      <span>{fls?.pair_to_show?.point}%</span>
                    </div>
                    <div className='h-2 bg-gray-200 rounded-md'>
                      <div
                        style={{ width: `${fls?.pair_to_show?.point}%` }}
                        className={`h-full bg-yellow-400 rounded-md`}
                      ></div>
                    </div>
                  </div>
                </div>
              ))
            : // ) : (
              //   <div>no data</div>
              // )
              Array.from({ length: 3 }).map((_, i) => (
                <div
                  key={i}
                  className='px-4 py-2 whitespace-nowrap w-full flex flex-row animate-pulse'
                >
                  <div></div>
                  <div className='w-full'>
                    <div className='h-3 rounded-md mb-2 bg-gray-300 w-1/4'></div>
                    <div className='h-2 bg-gray-300 rounded-md'></div>
                  </div>
                </div>
              ))}
          {!isInProfile && (
            <div className='mt-6 mr-4 text-right '>
              <Link
                className='text-gray-600 text-sm hover:text-gray-800 cursor-pointer'
                to={`/profile/${studentUuid}`}
                state={{ tab: 'preferred' }}
              >
                Go to preferred pair &rarr;
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
