import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LeaderboardTable({
  data,
  type,
  showStatsForSchool,
  showStatsForGrade,
  setGrade,
}) {
  const [t] = useTranslation();

  return (
    <div className='flex flex-col gap-4 mt-2'>
      <div className='border-2 rounded-xl p-2'>
        <table className='table-auto w-full'>
          <thead>
            <tr className='sticky top-0 bg-gray-200 border-b-2'>
              <th className='pb-2 px-2'>#</th>
              <th className='text-left'>
                {type === 'schools' ? t('stats.school') : 'Grade'}
              </th>
              <th>{t('studyplans.projects')}</th>
              <th>Language</th>
              <th>Science</th>
              <th>IT</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, index) => (
              <tr
                key={index}
                className='border-b-2 last:border-b-0 cursor-pointer hover:bg-gray-200 even:bg-gray-100'
                onClick={() => {
                  if (type === 'schools') {
                    showStatsForSchool(d?.uuid, d?.name);
                  }
                  if (type === 'grades') {
                    showStatsForGrade(d?.uuid);
                    setGrade(d);
                  }
                }}
              >
                <td className='py-1 px-2 text-center'>{index + 1}</td>
                <td>{d?.name}</td>
                <td className='text-center'>{d?.projects}</td>
                <td className='text-center'>{d?.language}</td>
                <td className='text-center'>{d?.science}</td>
                <td className='text-center'>{d?.it}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
