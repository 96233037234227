import React, { useRef } from 'react';
import blueHeaderImg from '../../assets/images/projects/Frame1869.svg';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useEffect } from 'react';

const Description = () => {
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    if (open) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setHeight('0px');
    }
  }, [open]);

  return (
    <div className='flex flex-col text-white'>
      <div
        id='bluediv'
        className='flex flex-col gap-8 items-center justify-center px-8 pt-12 pb-4 rounded-xl
            4xl:bg-[length:50%] xl:bg-[length:55%] bg-[length:80%]
            transition-all ease-in duration-500 cursor-pointer'
        style={{
          backgroundImage: `url(${blueHeaderImg})`,
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#2F80ED',
        }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div className='flex flex-row justify-center items-center gap-8'>
          <div className='text-3xl font-bold'>
            Level up your learning with projects!
          </div>
          {open ? (
            <ChevronUpIcon className='h-8' />
          ) : (
            <ChevronDownIcon className='h-8' />
          )}
        </div>
        <div
          ref={contentRef}
          className='flex flex-col gap-4 text-lg lg:px-24 md:px-20 px-4 transition-all duration-500 ease-in-out overflow-hidden'
          style={{ height: height }}
        >
          <div>
            <p className='whitespace-pre-line'>{`\n`}</p>
            <span className='font-semibold'>Projects</span> are designed to help
            you apply your knowledge in practical, hands-on ways. Each project
            is tailored to specific school subjects and comes with everything
            you need to succeed.
          </div>
          <div className='flex flex-col'>
            <p className='font-semibold'>Why Use Projects?</p>
            <div className='whitespace-pre-line'>
              {`- Choose projects based on your favorite subjects and difficulty level
- Each project comes with a detailed overview, including objectives, requirements, and step-by-step instructions.
- Make your project truly yours by using AI to customize it.
- Finish a project, and you’ll earn points that contribute to your personal indicators.`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
