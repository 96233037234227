/* eslint-disable no-unused-vars */
import { ArrowSmallUpIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../components/forms/Buttons/Button';
import TextArea from '../../../components/forms/Inputs/TextArea';
import Upload from '../../../components/upload/Upload';
import {
  getProjectSubmissions,
  postProjectSubmission,
} from '../../../services/projects/projectSubmissionService';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { EditorState } from 'draft-js';

const NewSubmission = ({ projectAssignment, refreshData }) => {
  const [t] = useTranslation();
  const { uuid } = useParams();
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [comment, setComment] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState([]);

  return (
    <div className='w-full flex flex-col gap-6 border-2 p-4 rounded-lg'>
      <div className='text-lg'>{t('projects.new_submission')}</div>
      <div className='text-gray-500 -mb-4'>
        {t('projects.submission_instructions')}
      </div>
      <div className='w-full flex flex-row gap-4'>
        <div className='w-full flex flex-col gap-4 shadow-md rounded-xl p-4'>
          <div>{t('projects.report_file')}</div>
          <Upload file={file1} setFile={setFile1} />
        </div>
        <div className='w-full flex flex-col gap-4 rounded-xl shadow-md p-4'>
          <div>{t('projects.report_file')}</div>
          <Upload file={file2} setFile={setFile2} />
        </div>
      </div>
      <div>
        {/* <Editor
          editorState={editorState}
          onEditorStateChange={(state) => setEditorState(state)}
        /> */}
        <TextArea
          // label={t('accounting.comment')}
          rows={8}
          className='border rounded-lg shadow-md'
          placeholder={t('projects.type_comment')}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className='flex flex-row justify-end'>
        <Button
          color={'success'}
          text={t('projects.submit')}
          Icon={ArrowSmallUpIcon}
          loading={loadingSubmit}
          onClick={() => {
            const body = new FormData();
            body.append('project_assignment', projectAssignment?.uuid);
            if (file1) body.append('file1', file1);
            if (file2) body.append('file2', file2);
            body.append('comment', comment);

            setLoadingSubmit(true);
            postProjectSubmission(body)
              .then((res) => {
                toast.success(t('projects.submitted_successfully'));
                refreshData();
              })
              .catch(() => {})
              .finally(() => setLoadingSubmit(false));
          }}
        />
      </div>
    </div>
  );
};

export default NewSubmission;
