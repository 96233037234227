import { PlusSmallIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/InputBil';
import Select from '../../../components/forms/Select/Select';
import { toast } from 'react-toastify';
import {
  postRoadmapTask,
  postTaskWithAI,
} from '../../../services/roadmapTask/roadmapTaskService';
import { UserContext } from '../../../utils/contexts/UserContext';
import { SparklesIcon } from '@heroicons/react/24/solid';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';
import SidebarModal from '../../../components/modals/SidebarModal/SidebarModal';

const Add = ({ domains, refreshData, studentUuid }) => {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const { id, setId } = useContext(SidebarContext);
  const [title, setTitle] = useState('');
  const [domain, setDomain] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [ShowSelect, setShowSelect] = useState(false);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [aiDescription, setAiDescription] = useState('');
  const [loadingAi, setLoadingAI] = useState(false);

  const uuid = useMemo(() => crypto.randomUUID(), []);

  const reset = () => {
    setTitle('');
    setDomain([]);
  };

  const onClose = () => {
    reset();
    setId('');
  };

  const getChildOptions = (selectedDomains) => {
    const childOptions = [];
    selectedDomains.forEach((domain) => {
      if (domain.childs && domain.childs.length > 0) {
        domain.childs.forEach((child) => {
          childOptions.push({ label: child.name, value: child.uuid });
        });
      }
    });
    return childOptions;
  };

  const hasChildsInSelectedDomains = () => {
    return (
      selectedDomains.length > 0 &&
      selectedDomains.some(
        (domain) => domain.childs && domain.childs.length > 0,
      )
    );
  };

  const selectedDomain = selectedDomains?.filter((domain) =>
    domain?.childs?.some((child) => child?.uuid === selectedStatus),
  )[0];

  useEffect(() => {
    if (id !== uuid) {
      reset();
    }
  }, [id, uuid]);

  return (
    <div>
      <Button
        Icon={PlusSmallIcon}
        text={t('roadmap.create_new_task')}
        onClick={() => {
          setId(uuid);
        }}
      />
      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.create_new_task')}
        HeaderIcon={PlusSmallIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            required
          />
          <Select
            label={t('stats.domain')}
            options={domains?.map((d) => ({ label: d?.name, value: d?.uuid }))}
            onChange={(e) => {
              // setDomain(e);
              setDomain(e.map((p) => p?.value));
              const selectedDomains = domains.filter((d) =>
                e.map((option) => option.value).includes(d.uuid),
              );
              setSelectedDomains(selectedDomains);

              const hasChilds = selectedDomains.some(
                (d) => d.childs && d.childs.length > 0,
              );
              setShowSelect(hasChilds);
            }}
            value={domain}
            isMulti
            closeMenuOnSelect={false}
            required
          />

          <div>
            {selectedStatus && hasChildsInSelectedDomains() && (
              <TextArea
                placeholder='here your promt'
                label={'Additional Promt'}
                value={aiDescription}
                onChange={(e) => setAiDescription(e.target.value)}
              />
            )}
          </div>

          <div className='flex flex-row justify-between items-center '>
            {ShowSelect && (
              <>
                <Select
                  className='w-56'
                  placeholder={'something'}
                  isSearchable={false}
                  isClearable={true}
                  value={selectedStatus}
                  options={getChildOptions(selectedDomains)}
                  onChange={(e) => {
                    setSelectedStatus(e?.value);
                  }}
                />
                {selectedStatus && (
                  <Button
                    loading={loadingAi}
                    onClick={() => {
                      if (!title || domain?.length === 0) {
                        toast.error(t('roadmap.required_fields_empty'));
                        return;
                      }

                      const body = {};
                      body['domain_id'] = selectedDomain.uuid;
                      body['subdomain_id'] = selectedStatus;
                      body['user_input'] = aiDescription;
                      body['title'] = title;

                      setLoadingAI(true);
                      postTaskWithAI(body)
                        .then(() => {
                          toast.success(t('roadmap.added_successfully'));
                          refreshData();
                          onClose();
                        })
                        .finally(() => {
                          setLoadingSubmit(false);
                          setLoadingAI(false);
                        });
                    }}
                  >
                    Fill With AI{' '}
                    <SparklesIcon className='h-5 text-warning'></SparklesIcon>{' '}
                  </Button>
                )}
              </>
            )}
          </div>

          <div className='flex flex-row justify-center mt-4'>
            <Button
              text={t('roadmap.add')}
              loading={loadingSubmit}
              onClick={() => {
                if (!title || domain?.length === 0) {
                  toast.error(t('roadmap.required_fields_empty'));
                  return;
                }

                const body = {};
                body['name'] = title;
                body['domains'] = domain;
                body['student'] = studentUuid ?? user?.uuid;
                body['assignor'] = user?.uuid;

                setLoadingSubmit(true);
                postRoadmapTask(body)
                  .then(() => {
                    toast.success(t('roadmap.added_successfully'));
                    refreshData();
                    onClose();
                  })
                  .finally(() => setLoadingSubmit(false));
              }}
            />
          </div>
        </div>
      </SidebarModal>
    </div>
  );
};

export default Add;
