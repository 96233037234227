import React, { Fragment, useRef, useState } from 'react';
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../components/forms/Checkbox/Checkbox';
import { patchRoadmapSubtask } from '../../services/roadmapTask/roadmapSubtaskService';
import AddSub from './BREAD/AddSub';
import Edit from './BREAD/Edit';
import EditSub from './BREAD/EditSub';
import './Timeline.css';
import AddSubAI from './BREAD/AddSubAI';

const getDaysFromYearStart = (date) => {
  date = new Date(date);
  let year_start = new Date(`01-01-${new Date().getFullYear()}`);
  let Difference_In_Time = date.getTime() - year_start.getTime();
  return Math.round(Difference_In_Time / (1000 * 3600 * 24));
};

const Timeline = ({ data, refreshData, domains, studentUuid }) => {
  const [t] = useTranslation();

  const [isOpen, setIsOpen] = useState(new Array(data?.length).fill(false));
  const date = new Date().getFullYear();
  const startDate = date;
  const endDate = date + 1;
  const refMonth = useRef([]);

  const MONTHS = {
    Jan: 31,
    Feb: 28,
    Mar: 31,
    Apr: 30,
    May: 31,
    Jun: 30,
    Jul: 31,
    Aug: 31,
    Sep: 30,
    Oct: 31,
    Nov: 30,
    Dec: 31,
  };

  return (
    <div className='flex flex-row rounded-lg shadow-md border'>
      {/* Color loaders start */}
      <div className='invisible absolute bg-gradient-to-r from-success via-amethyst to-primary' />
      <div className='invisible absolute bg-gradient-to-r from-amethyst via-primary to-success' />
      <div className='invisible absolute bg-gradient-to-r from-primary via-success to-amethyst' />
      {/* Color loaders end */}

      <div className='overflow-auto'>
        <table className='table-auto w-1/2 border-separate border-spacing-0 mt-4 '>
          <thead>
            <tr className='text-gray-500 border-b min-w-fit'>
              <th className='border-b border-r px-2 min-w-[5rem] bg-white sticky left-0'>
                {t('roadmap.type')}
              </th>
              <th className='text-left border-b border-r px-2 bg-white sticky left-20'>
                {t('roadmap.title')}
              </th>
              <th className='border-b border-r min-w-[10rem] bg-white sticky left-[25rem]'>
                {t('roadmap.progress')}
              </th>
              <th className='border-b px-2 flex flex-row overflow-visible w-full'>
                {[...Array((endDate - startDate + 1) * 12).keys()].map(
                  (month, i) => {
                    let monthKey = Object.keys(MONTHS)[month % 12];
                    return (
                      <div
                        key={`${monthKey} ${
                          Math.floor(month / 12) + startDate
                        }`}
                        ref={refMonth.current[month]}
                        style={{ minWidth: `${MONTHS[monthKey] / 4}rem` }}
                        className='text-center border-r last:border-r-0'
                      >
                        {monthKey} {Math.floor(month / 12) + startDate}
                      </div>
                    );
                  },
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((d, index) => {
              let colors = [];
              let task_domains = d.domains.map((domain) => domain.code);
              const prefixOfColor = (color, index) => {
                if (!index && task_domains.length === 1)
                  return `from-${color} to-${color}`;
                if (!index) return `from-${color}`;
                if (index === 1 && task_domains.length === 2)
                  return `to-${color}`;
                if (index === 1) return `via-${color}`;
                if (index === 2) return `to-${color}`;
              };
              if (task_domains.includes('ACADEMIC'))
                colors.push(
                  prefixOfColor('success', task_domains.indexOf('ACADEMIC')),
                );
              if (task_domains.includes('CAREER'))
                colors.push(
                  prefixOfColor('amethyst', task_domains.indexOf('CAREER')),
                );
              if (task_domains.includes('SOCIAL'))
                colors.push(
                  prefixOfColor('primary', task_domains.indexOf('SOCIAL')),
                );
              colors = colors.join(' ');
              return (
                <Fragment key={d.uuid}>
                  <tr
                    key={d.uuid}
                    className='last:border-b-0 hover:bg-gray-100 h-10'
                  >
                    <td className='py-2 border-b border-r bg-white z-[1] px-2 sticky left-0'>
                      <div className='flex flex-row items-center gap-1 justify-center'>
                        {isOpen[index] ? (
                          <ChevronUpIcon
                            className='h-4 cursor-pointer'
                            onClick={() => {
                              setIsOpen((prev) => {
                                let temp = [...prev];
                                temp[index] = false;
                                return temp;
                              });
                            }}
                          />
                        ) : (
                          <ChevronDownIcon
                            className='h-4 cursor-pointer'
                            onClick={() => {
                              setIsOpen((prev) => {
                                let temp = [...prev];
                                temp[index] = true;
                                return temp;
                              });
                            }}
                          />
                        )}
                        <CalendarIcon className='h-5 text-success' />
                      </div>
                    </td>
                    <td
                      className='lg:text-base text-sm border-b border-r min-w-[20rem] max-w-[20rem] w-80 bg-white z-[1] px-2 sticky left-20'
                      title={d.name}
                    >
                      <Edit
                        datum={d}
                        domains={domains}
                        refreshData={refreshData}
                        className='hover:text-primary px-2 py-1.5 h-full truncate cursor-pointer'
                      />
                    </td>
                    <td className='font-light lg:text-base text-sm text-center border-b border-r bg-white z-[1] px-2 sticky left-[25rem]'>
                      {Math.round((d?.done_count / d?.children_count) * 10000) /
                        100 || 0}
                      %
                    </td>
                    <td className='border-b relative overflow-hidden'>
                      <div
                        style={{
                          left: `${getDaysFromYearStart(d.start_date) / 4}rem`,
                          width: `${
                            (getDaysFromYearStart(d.deadline) -
                              getDaysFromYearStart(d.start_date)) /
                            4
                          }rem`,
                        }}
                        className={`h-5 rounded-lg bg-gradient-to-r ${colors} absolute transform -translate-y-2/4`}
                      />
                    </td>
                  </tr>
                  {isOpen[index] &&
                    d?.children?.map((c) => (
                      <tr className={` hover:bg-gray-100`} key={c?.uuid}>
                        <td className='py-2 border-b border-r px-2 bg-white z-[1] h-10 sticky left-0 '>
                          <div className='flex flex-row items-center gap-1 justify-center'>
                            <ChevronDownIcon className='h-4 invisible' />

                            <CalendarIcon className='h-5 text-primary' />
                          </div>
                        </td>
                        <td
                          className='lg:text-base text-sm border-b border-r px-2 bg-white z-[1] max-w-[20rem] h-10 sticky left-20'
                          title={c.name}
                        >
                          <EditSub
                            datum={c}
                            refreshData={refreshData}
                            taskStatus={d?.status}
                            className='hover:text-primary px-2 py-1.5 h-full truncate cursor-pointer'
                          />
                        </td>
                        <td className='border-r border-b bg-white z-[1] sticky left-[25rem]'>
                          <div className='flex flex-row justify-center'>
                            <Checkbox
                              checked={c?.done}
                              disabled={d?.status === 'finished'}
                              onChange={() => {
                                const body = new FormData();
                                body.append('done', !c?.done);
                                patchRoadmapSubtask(c?.uuid, body)
                                  .then(() => {
                                    refreshData();
                                  })
                                  .catch(() => {});
                              }}
                            />
                          </div>
                        </td>
                        <td className='border-b relative h-10 break-all overflow-hidden'>
                          <div
                            style={{
                              left: `${
                                getDaysFromYearStart(c.start_date) / 4
                              }rem`,
                              width: `${
                                (getDaysFromYearStart(c.deadline) -
                                  getDaysFromYearStart(c.start_date)) /
                                4
                              }rem`,
                            }}
                            className={`h-5 rounded-lg bg-gradient-to-r ${colors} absolute transform -translate-y-2/4`}
                          />
                        </td>
                      </tr>
                    ))}
                  {d?.status !== 'finished' && isOpen[index] && (
                    <tr className='hover:bg-gray-100 h-10' key={index}>
                      <td className='py-2 border-b border-r px-2 sticky left-0 flex flex-row items-center gap-1 justify-center h-10'>
                        <ChevronUpIcon className='h-4 invisible' />
                        <AddSub
                          task={d?.uuid}
                          refreshData={refreshData}
                          studentUuid={studentUuid}
                        />
                        <AddSubAI
                          task={d}
                          taskuuid={d?.uuid}
                          refreshData={refreshData}
                          studentUuid={studentUuid}
                        />
                      </td>
                      <td className='border-r border-b sticky left-20' />
                      <td className='border-r border-b sticky left-[25rem]' />
                      <td className='border-r border-b' />
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Timeline;
