import React, { useRef } from 'react';
// import headerImg from '../../assets/images/roadmap/Frame1846.svg';
import blueHeaderImg from '../../assets/images/projects/Frame1888.svg';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useEffect } from 'react';

const Description = () => {
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    if (open) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setHeight('0px');
    }
  }, [open]);

  return (
    <div className='flex flex-col text-white'>
      <div
        id='bluediv'
        className='flex flex-col gap-8 items-center justify-center px-8 pt-12 pb-4 rounded-xl
            4xl:bg-[length:50%] xl:bg-[length:55%] bg-[length:80%]
            transition-all ease-in duration-500 cursor-pointer'
        style={{
          backgroundImage: `url(${blueHeaderImg})`,
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#2F80ED',
        }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div className='flex flex-row justify-center items-center gap-8'>
          <div className='text-3xl font-bold'>
            How to assign a reviewer for Project submissions
          </div>
          {open ? (
            <ChevronUpIcon className='h-8' />
          ) : (
            <ChevronDownIcon className='h-8' />
          )}
        </div>
        <div
          ref={contentRef}
          className='flex flex-col gap-4 text-lg lg:px-24 md:px-20 px-4 transition-all duration-500 ease-in-out overflow-hidden'
          style={{ height: height }}
        >
          <div>
            As a School admin, you play a key role in ensuring that each project
            submission is reviewed effectively. Follow these simple steps to
            assign a reviewer:
          </div>
          <div className='flex flex-col gap-1'>
            <div className='font-semibold'>Locate the Submission:</div>
            <div>Find the project submission that needs a reviewer. </div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='font-semibold'>Choose a Reviewer:</div>
            <div>
              In the "Reviewer" field of the submission, click the dropdown
              menu. A list of available reviewers will appear. Carefully select
              the name of the appropriate reviewer for this project.
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='font-semibold'>Confirm Your Selection: </div>
            <div>
              After selecting the reviewer, ensure that the correct name is
              displayed in the "Reviewer" field.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
