import { ArrowUpTrayIcon, DocumentIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Upload = ({
  className,
  uploadText = 'Upload file',
  file,
  setFile,
  sizeLimit = 4194304,
  accept,
}) => {
  const [t] = useTranslation();
  const inputRef = React.useRef(null);
  const [isOversized, setIsOversized] = useState(false);

  return (
    <div
      className={`py-8 flex flex-col ${
        file ? '' : 'border-dashed'
      } border-8 gap-2 justify-center items-center rounded-xl px-8 ${
        isOversized ? 'border-error' : ''
      }  bg-opacity-10  cursor-pointer ${
        isOversized && 'border border-error'
      } ${className}`}
      onClick={() => inputRef.current.click()}
    >
      {file ? (
        <DocumentIcon className='w-1/5 text-gray-300 max-h-20 min-h-10' />
      ) : (
        <ArrowUpTrayIcon className='w-1/5 text-gray-300 max-h-20 min-h-10' />
      )}
      <p className={`truncate max-w-sm ${file?.name ? '' : 'text-gray-400'}`}>
        {isOversized
          ? t('component.file_too_large')
          : file
          ? file?.name
            ? file?.name
            : file?.split('/')?.length
            ? file?.split('/')[file?.split('/')?.length - 1]
            : file
          : uploadText}
      </p>
      <input
        ref={inputRef}
        type='file'
        accept={accept}
        style={{ display: 'none' }}
        onChange={(e) => {
          if (!e?.target?.files[0]) {
            setFile();
            return;
          }
          if (e?.target?.files[0]?.size < sizeLimit) {
            setIsOversized(false);
            setFile(e?.target?.files[0]);
          } else {
            setIsOversized(true);
          }
        }}
      />
    </div>
  );
};

export default Upload;
