import React, { useEffect, useState } from 'react';
import { getStudentsStatsBySkills } from '../../services/stats/statsService';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { useTranslation } from 'react-i18next';
import { getSkills } from '../../services/projects/skillsService';
import { Button } from '../../components/forms/Buttons/Button';
import { useNavigate } from 'react-router-dom';

const PAGE_SIZE = 100;

const Table = ({ org }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [skills, setSkills] = useState([]);
  // eslint-disable-next-line
  const [loadingSkills, setLoadingSkills] = useState(false);
  // eslint-disable-next-line
  const [headers, setHeaders] = useState([]);
  const [n, setN] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoadingSkills(true);
    getSkills({})
      .then((res) => {
        // console.log('skills', res?.results);
        setSkills(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoadingSkills(false));
  }, []);

  const addData = () => {
    setLoadingMore(true);
    getStudentsStatsBySkills({
      organization: org,
      page_size: PAGE_SIZE,
      page: page + 1,
    })
      .then((res) => {
        for (let r of res?.results) {
          for (let k of Object.keys(r).filter(
            (x) => x.slice(0, 6) === 'skill_',
          )) {
            if (!headers.includes(k)) {
              headers.push(k);
            }
          }
        }

        setData((prev) => prev.concat(res?.results));
        setPage(page + 1);
      })
      .catch(() => {})
      .finally(() => setLoadingMore(false));
  };

  useEffect(() => {
    if (skills?.length) {
      setLoadingData(true);
      getStudentsStatsBySkills({
        organization: org,
        page_size: PAGE_SIZE,
        page: 1,
      })
        .then((res) => {
          console.log('data', res?.results);
          for (let r of res?.results) {
            for (let k of Object.keys(r).filter(
              (x) => x.slice(0, 6) === 'skill_',
            )) {
              if (!headers.includes(k)) {
                headers.push(k);
              }
            }
          }

          // setData((prev) => prev.concat(res?.results));
          setData(res?.results);
          setN(res?.count);
          setPage(1);
        })
        .catch(() => {})
        .finally(() => setLoadingData(false));
    }
    // eslint-disable-next-line
  }, [skills, org]);

  return (
    <div className='flex flex-col gap-4'>
      {loadingData && page === 1 ? (
        <div className='flex flex-col p-8 items-center'>
          <SimpleLoader className={'h-12 w-12'} />
        </div>
      ) : (
        <div className='border-2 rounded-xl p-2'>
          <table className='table-auto w-full'>
            <thead>
              <tr className='border-b-2 sticky top-0 bg-white'>
                <th className='pb-2 px-2'>#</th>
                <th className='text-left'>{t('stats.student')}</th>
                <th className='text-left'>{t('stats.school')}</th>
                <th>{t('studyplans.projects')}</th>
                {headers.map((h) => (
                  <th key={h} className='last:pr-2'>
                    {skills?.find((s) => s?.uuid === h.slice(6))?.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((d, index) => (
                <tr
                  key={index}
                  className='cursor-pointer border-b-2 last:border-b-0 even:bg-gray-100 hover:bg-gray-200'
                  onClick={() => navigate(`/profile/${d?.uuid}`)}
                >
                  <td className='py-1 px-2 text-center'>{index + 1}</td>
                  <td>{`${d?.first_name} ${d?.last_name}`}</td>
                  <td>{d?.organization?.name}</td>
                  <td className='text-center'>{d?.projects}</td>
                  {headers.map((h) => (
                    <td key={h} className='last:pr-2 text-center'>
                      {Object.keys(d).includes(h) ? d[h] : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {page * PAGE_SIZE < n && (
            <div className='flex flex-row justify-center py-4'>
              <Button
                loading={loadingMore}
                text={t('stats.see_more')}
                onClick={() => addData()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Table;
