import {
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  LightBulbIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { getProjectCredits } from '../../services/projects/projectCreditsService';

export default function SoftSkillsCard({ studentUuid }) {
  const [softSkills, setSoftSkills] = useState(null);

  useEffect(() => {
    if (studentUuid) {
      getProjectCredits(studentUuid, 'soft_skills').then((res) => {
        setSoftSkills(res);
      });
    }

    return () => {};
  }, [studentUuid]);

  return (
    <div className='bg-white border rounded-md shadow-md px-4 pt-2 pb-12'>
      <h2 className='font-semibold text-lg'>Soft Skills</h2>
      <div className='flex flex-col md:flex-row justify-between items-center md:items-start'>
        <div className='h-28 w-28 relative mt-8 ml-4'>
          <div className='h-28 w-28 border-8 border-orange-400 rounded-full flex flex-col items-center justify-center text-orange-400 text-4xl font-semibold'>
            <div className='text-black'>
              {softSkills?.uuid
                ? softSkills?.analytical_thinking +
                  softSkills?.communication_skill +
                  softSkills?.creative_thinking +
                  softSkills?.organizational_skill
                : 0}
            </div>
            <div className='text-center text-gray-500 text-sm mt-2'>xp</div>
          </div>
        </div>

        <div className='flex flex-col w-2/3'>
          <div className=' px-4 py-2 whitespace-nowrap w-full flex flex-row'>
            <div>
              <ChartBarIcon className='h-6 inline-block mr-2' />
            </div>
            <div className='w-full'>
              <div className='flex flex-row justify-between text-sm'>
                Analytical thinking
                <span>{softSkills?.analytical_thinking}xp</span>
              </div>
              <div className='h-2 bg-blue-200 rounded-md'>
                <div
                  style={{ width: `${softSkills?.analytical_thinking * 10}%` }}
                  className='h-2 bg-blue-400 rounded-md'
                ></div>
              </div>
            </div>
          </div>
          <div className='px-4 py-2 whitespace-nowrap w-full flex flex-row'>
            <div>
              <LightBulbIcon className='h-6 inline-block mr-2' />
            </div>
            <div className='w-full'>
              <div className='flex flex-row justify-between text-sm'>
                Creative thinking
                <span>{softSkills?.creative_thinking}xp</span>
              </div>
              <div className='h-2 bg-yellow-200 rounded-md'>
                <div
                  style={{ width: `${softSkills?.creative_thinking * 10}%` }}
                  className='h-2 bg-yellow-400 rounded-md'
                ></div>
              </div>
            </div>
          </div>
          <div className='px-4 py-2 whitespace-nowrap w-full flex flex-row'>
            <div>
              <ChatBubbleLeftRightIcon className='h-6 inline-block mr-2' />
            </div>
            <div className='w-full'>
              <div className='flex flex-row justify-between text-sm'>
                Communication skill
                <span>{softSkills?.communication_skill}xp</span>
              </div>
              <div className='h-2 bg-red-200 rounded-md'>
                <div
                  style={{ width: `${softSkills?.communication_skill * 10}%` }}
                  className='h-2 bg-red-400 rounded-md'
                ></div>
              </div>
            </div>
          </div>
          <div className='px-4 pt-2 whitespace-nowrap w-full flex flex-row'>
            <div>
              <UserGroupIcon className='h-6 inline-block mr-2' />
            </div>
            <div className='w-full'>
              <div className='flex flex-row justify-between text-sm'>
                Organizational skill
                <span>{softSkills?.organizational_skill}xp</span>
              </div>
              <div className='h-2 bg-gray-200 rounded-md'>
                <div
                  style={{ width: `${softSkills?.organizational_skill * 10}%` }}
                  className='h-2 bg-gray-600 rounded-md'
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
