import React, { useContext, useEffect, useMemo, useState } from 'react';
import PreferredPairs from './PreferredPairs/PreferredPairs';
import { UserContext } from '../../../utils/contexts/UserContext';
import { Tab } from '@headlessui/react';
import Progress from './Progress/Progress';
import Projects from './Projects/Projects';
// import StudentProfileCard from './StudentProfileCard';
import Performance from './Performance/Performance';
import TestResults from './TestResults/TestResults';
import RoadmapTab from './RoadmapTab/RoadmapTab';
import ProjectSets from './ProjectSets/ProjectSets';
import Courses from './Courses/Courses';
import ProfileInfo from '../ProfileInfo';
import { getAccount } from '../../../services/auth/authService';

export default function StudentProfile({ uuid, tab }) {
  const { user } = useContext(UserContext);
  const [profileUser, setProfileUser] = useState();
  const [loading, setLoading] = useState(false);

  const MY_TABS = useMemo(
    () => [
      {
        key: 'progress',
        title: 'Progress',
        content: <Progress uuid={uuid} isOwnProfile />,
      },
      {
        key: 'performance',
        title: 'Performance',
        content: <Performance uuid={uuid} isOwnProfile />,
      },
      {
        key: 'projects',
        title: 'My projects',
        content: <Projects uuid={uuid} isOwnProfile />,
      },
      {
        key: 'preferredPairs',
        title: 'Preferred Pairs',
        content: <PreferredPairs uuid={uuid} isOwnProfile />,
      },
    ],
    [uuid],
  );
  const TABS = useMemo(
    () => [
      {
        key: 'roadmap',
        title: 'Roadmap',
        content: <RoadmapTab studentUuid={uuid} />,
      },
      {
        key: 'progress',
        title: 'Progress',
        content: <Progress uuid={uuid} />,
      },
      {
        key: 'preferredPairs',
        title: 'Preferred Pairs',
        content: <PreferredPairs uuid={uuid} />,
      },
      {
        key: 'test',
        title: 'Personality & skills',
        content: <TestResults studentUuid={uuid} />,
      },
      {
        key: 'performance',
        title: 'Performance',
        content: <Performance uuid={uuid} />,
      },
      {
        key: 'courses',
        title: 'Courses',
        content: <Courses uuid={uuid} />,
      },
      {
        key: 'projectSets',
        title: 'Project Sets',
        content: <ProjectSets uuid={uuid} />,
      },
      {
        key: 'projects',
        title: 'Projects',
        content: <Projects uuid={uuid} />,
      },
    ],
    [uuid],
  );

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOwnProfile, setIsOwnProfile] = useState(null);

  useEffect(() => {
    if (user?.uuid && uuid) {
      if (user.uuid === uuid) {
        setIsOwnProfile(true);
      } else {
        setIsOwnProfile(false);
      }
    }

    return () => {};
  }, [uuid, user]);

  useEffect(() => {
    switch (tab) {
      case 'preferred':
        setSelectedIndex(isOwnProfile ? 3 : 2);
        break;
      case 'projects':
        setSelectedIndex(isOwnProfile ? 2 : 7);
        break;
      default:
        break;
    }
    return () => {};
  }, [isOwnProfile, tab]);

  const getAccountData = () => {
    setLoading(true);
    getAccount(uuid)
      .then((res) => {
        setProfileUser(res);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (uuid) {
      getAccountData();
    }
    return () => {};
    // eslint-disable-next-line
  }, [uuid]);

  return (
    <div className='w-full flex flex-col gap-4  px-4 py-2'>
      <ProfileInfo
        isOwnProfile={isOwnProfile}
        user={profileUser}
        loading={loading}
        refreshData={getAccountData}
      />
      {/* {!isOwnProfile && <StudentProfileCard studentUuid={uuid} />} */}
      {profileUser?.roles?.some((r) => r?.name === 'STUDENT') && (
        <div className='flex flex-col gap-4 border rounded-md'>
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className='flex flex-row gap-5 border-b border-gray-200'>
              {(isOwnProfile ? MY_TABS : TABS)?.map((tab) => {
                return (
                  <Tab
                    key={tab.key}
                    className={({ selected }) =>
                      selected
                        ? 'py-2 text-sm font-medium px-4 border-b-2 border-blue-500'
                        : 'py-2 text-sm font-medium px-4 text-gray-500 hover:border-b-2 hover:border-gray-300 hover:text-gray-800'
                    }
                  >
                    {tab.title}
                  </Tab>
                );
              })}
            </Tab.List>
            <Tab.Panels>
              {(isOwnProfile ? MY_TABS : TABS)?.map((tab) => {
                return <Tab.Panel key={tab.key}>{tab.content}</Tab.Panel>;
              })}
            </Tab.Panels>
          </Tab.Group>
        </div>
      )}
    </div>

    // <div className='flex flex-col border rounded-md bg-white shadow-md mx-2 md:mx-0'>
    //   <PreferredPairs />
    // </div>
  );
}
