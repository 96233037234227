import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getRoadmapTasks } from '../../../services/roadmapTask/roadmapTaskService';
import { UserContext } from '../../../utils/contexts/UserContext';
import { getRoadmaps } from '../../../services/roadmapTask/roadmapService';
import Select from '../../../components/forms/Select/Select';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
// import Edit from '../BREAD/Edit';
import moment from 'moment';
import Checkbox from '../../../components/forms/Checkbox/Checkbox';
import { patchRoadmapSubtask } from '../../../services/roadmapTask/roadmapSubtaskService';

const PAGE_SIZE = 100;

const MyRoadmap = ({ roadmap }) => {
  const { user } = useContext(UserContext);

  const [tasks, setTasks] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(false);

  const getTasksData = useCallback(() => {
    if (!roadmap?.uuid) return;
    setLoadingTasks(true);
    getRoadmapTasks({
      roadmap: roadmap?.uuid,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setTasks(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoadingTasks(false));
  }, [roadmap]);

  useEffect(() => {
    getTasksData();
  }, [getTasksData]);

  const [isOpen, setIsOpen] = useState(
    new Map(tasks?.map((d) => [d?.uuid, false])),
  );

  return (
    <div className='flex flex-col gap-4 p-4'>
      {/* <Description /> */}
      <div className='flex flex-row gap-2'>
        <div className='border rounded-md p-1.5 px-4'>{roadmap?.name}</div>
        {roadmap && (
          <>
            <div className='border rounded-md p-1.5 px-4'>
              {moment(roadmap.end_date, 'YYYY-MM-DD')?.diff(
                moment(roadmap.start_date, 'YYYY-MM-DD'),
                'week',
              )}{' '}
              weeks
            </div>
            <div className='border rounded-md p-1.5 px-4'>
              {roadmap.task_count} tasks
            </div>
          </>
        )}
      </div>

      <div className='flex flex-row justify-between'></div>
      {loadingTasks ? (
        <div className='flex flex-row justify-center pt-12'>
          <SimpleLoader className={'w-10 h-10'} />
        </div>
      ) : tasks?.length ? (
        <div className='border rounded-2xl'>
          <table className='w-full'>
            <thead>
              <th className='w-fit p-2 px-4'>Status</th>
              <th className='text-left w-1/3 p-2 px-4'>Title</th>
              <th className='w-fit p-2 px-4'>Deadline</th>
              <th className='w-full p-2 px-4'>Progress</th>
            </thead>
            <tbody>
              {tasks.map((task) => {
                return (
                  <React.Fragment key={task.uuid}>
                    <tr key={task.uuid} className='odd:bg-slate-100'>
                      <td className='flex flex-row items-center justify-center p-2 px-4 gap-1'>
                        {isOpen.get(task?.uuid) ? (
                          <ChevronUpIcon
                            className='h-4 cursor-pointer'
                            onClick={() => {
                              setIsOpen((prev) => {
                                let temp = new Map(prev);
                                temp.set(task?.uuid, false);
                                return temp;
                              });
                            }}
                          />
                        ) : (
                          <ChevronDownIcon
                            className='h-4 cursor-pointer'
                            onClick={() => {
                              setIsOpen((prev) => {
                                let temp = new Map(prev);
                                temp.set(task?.uuid, true);
                                return temp;
                              });
                            }}
                          />
                        )}
                        <CalendarIcon className='h-5 text-success' />
                      </td>
                      <td className='p-2 px-4'>
                        {task.name}
                        {/* <Edit
                        datum={task}
                        // domains={domains}
                        // refreshData={refreshData}
                        className='hover:text-primary px-2 py-1.5 h-full truncate cursor-pointer'
                      /> */}
                      </td>
                      <td className='flex flex-row justify-center items-center p-2 px-4'>
                        {task.deadline && (
                          <div className='p-1 rounded bg-gray-200 w-28 text-center'>
                            {format(new Date(task.deadline), 'MMM d, yyyy')}
                          </div>
                        )}
                      </td>
                      <td className='p-2 px-4 '>
                        {task?.children_count ? (
                          <div className='px-2 text-center'>
                            <div className='text-xs text-gray-600'>{`${task?.done_count}/${task?.children_count}`}</div>
                            <div className='relative h-2 rounded-md bg-sheet-gray'>
                              <div
                                className='h-2 rounded-md bg-primary absolute'
                                style={{
                                  width: `${
                                    (task?.done_count / task?.children_count) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        ) : undefined}
                      </td>
                    </tr>
                    {isOpen.get(task?.uuid) &&
                      task?.children?.map((c) => (
                        <tr className='odd:bg-slate-100' key={c?.uuid}>
                          <td className='flex flex-row items-center justify-center p-2 px-4 gap-1'>
                            <div className='flex flex-row items-center gap-1 justify-center'>
                              <CalendarIcon className='h-4 invisible' />
                              <CalendarIcon className='h-5 text-primary' />
                            </div>
                          </td>
                          <td
                            className='p-2 px-4
                      '
                          >
                            {c.name}
                          </td>

                          <td className='flex flex-row justify-center items-center p-2 px-4'>
                            {task.deadline && (
                              <div className='p-1 rounded bg-gray-200 w-28 text-center'>
                                {format(new Date(c.deadline), 'MMM d, yyyy')}
                              </div>
                            )}
                          </td>
                          <td className=''>
                            <div className='flex flex-row justify-center'>
                              <Checkbox
                                checked={c?.done}
                                onChange={() => {
                                  const body = new FormData();
                                  body.append('done', !c?.done);
                                  patchRoadmapSubtask(c?.uuid, body)
                                    .then(() => {
                                      getTasksData();
                                    })
                                    .catch(() => {});
                                }}
                                disabled={task?.status === 'finished'}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    {/* {task?.status !== 'finished' && isOpen.get(task?.uuid) && (
                      <tr className='border-b last:border-b-0 hover:bg-gray-100 h-10'>
                        <td className='py-2 border-r px-2'>
                          <div className='flex flex-row items-center gap-1 justify-center'></div>
                        </td>
                        <td className='lg:text-bas text-sm border-r px-2'></td>
                        <td className='font-light lg:text-base text-sm text-center border-r px-2'></td>
                        <td
                          className={`lg:text-base text-sm pl-1 pr-2 w-48 border-r px-2`}
                        ></td>
                        <td className='text-center border-r px-2'></td>
                        <td className=''></td>
                      </tr>
                    )} */}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          className='flex flex-row justify-center py-8 mt-4 text-gray-400 text-lg
          border rounded-lg'
        >
          No content
        </div>
      )}
    </div>
  );
};

export default MyRoadmap;
