import React, { useEffect, useState } from 'react';
import { getAccountDatum } from '../../../../services/accountData/accountDataService';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import BronzeMedal from '../../../../components/Icons/BronzeMedal';
import SilverMedal from '../../../../components/Icons/SilverMedal';
import GoldMedal from '../../../../components/Icons/GoldMedal';
import ReactApexChart from 'react-apexcharts';

const getPercentageColorForApex = (percentage) => {
  if (percentage <= 0.25) return ['#e7483d'];
  else if (percentage <= 0.5) return ['#F45B2B'];
  else if (percentage <= 0.75) return ['#6DD24A'];
  else return ['#2C59F8'];
};

export default function Performance({ uuid }) {
  const [t] = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState();
  const [btsSliced, setBtsSliced] = useState(2);
  const [untSliced, setUntSliced] = useState(2);
  const [kboSliced, setKboSliced] = useState(2);
  const [olympiadSliced, setOlympiadSliced] = useState(2);

  useEffect(() => {
    if (uuid) {
      setLoadingData(true);
      getAccountDatum(uuid)
        .then((res) => {
          // console.log('res', res);
          setData(res);
        })
        .catch(() => {})
        .finally(() => setLoadingData(false));
    }
  }, [uuid]);

  return (
    <div>
      {loadingData ? (
        <div className='flex flex-row justify-center py-12'>
          <SimpleLoader className={'w-14 h-14'} />
        </div>
      ) : data?.data?.exam_ratings ? (
        <div className='flex flex-col gap-8'>
          {data?.data?.exam_ratings?.Olympiad?.length ? (
            <div className='border rounded-lg'>
              <div className='flex flex-col gap-4 p-4'>
                <div className='text-xl font-semibold'>
                  {t('performance.olympiad')}
                </div>
                <div className='flex flex-col gap-4'>
                  {data?.data?.exam_ratings?.Olympiad?.slice(
                    0,
                    olympiadSliced
                      ? 2
                      : data?.data?.exam_ratings?.Olympiad?.length,
                  )?.map((er) => (
                    <div
                      key={Math.random()}
                      className='flex flex-col gap-2 border rounded-lg py-4 px-6 shadow-md'
                    >
                      <p className='font-medium text-lg'>
                        {er?.olympiad_name}
                        {'\u00A0\u00A0'}
                        <span className='text-gray-500 font-normal'>
                          {er?.date}
                        </span>
                      </p>
                      <div className='w-full flex flex-col gap-4'>
                        <div className='flex flex-row justify-between'>
                          <div className='flex flex-col'>
                            <div>{`${t('performance.olympiad_type')}: ${
                              er?.regional_status === 0
                                ? t('performance.regional')
                                : er?.regional_status === 1
                                ? t('performance.national')
                                : er?.regional_status === 2
                                ? t('performance.international')
                                : t('performance.undefined')
                            }`}</div>

                            <div>{`${t('performance.result')}: ${
                              er?.medal === 'bronze'
                                ? t('performance.bronze')
                                : er?.medal === 'silver'
                                ? t('performance.silver')
                                : er?.medal === 'gold'
                                ? t('performance.gold')
                                : t('performance.none')
                            }`}</div>
                          </div>
                          <div>
                            {er?.medal === 'bronze' ? (
                              <BronzeMedal className='h-10 w-10' />
                            ) : er?.medal === 'silver' ? (
                              <SilverMedal className='h-10 w-10' />
                            ) : er?.medal === 'gold' ? (
                              <GoldMedal className='h-10 w-10' />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {er?.subjects?.map((s) => (
                          <div
                            key={s?.subject_name}
                            className='flex flex-col gap-1'
                          >
                            <div className='flex flex-row justify-between'>
                              <div>{s?.subject_name}</div>
                              <div>{`${s?.total_correct}/${s?.total_question}`}</div>
                            </div>
                            <div className='w-full relative rounded-lg h-4 bg-gray-200'>
                              <div
                                className='absolute h-4 rounded-lg text-white 
                              flex flex-row items-center px-2 text-xs'
                                style={{
                                  width: `${
                                    (s?.total_correct / s?.total_question) * 100
                                  }%`,
                                  backgroundColor: getPercentageColorForApex(
                                    s?.total_correct / s?.total_question,
                                  ),
                                }}
                              >
                                {s?.total_correct &&
                                s?.total_correct / s?.total_question > 0.19
                                  ? `${(
                                      (s?.total_correct / s?.total_question) *
                                      100
                                    ).toFixed(0)}%`
                                  : ''}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {data?.data?.exam_ratings?.Olympiad?.length > 2 && (
                <div>
                  <div
                    className={`flex flex-row justify-center px-4 -mt-2 ${
                      olympiadSliced ? ' -mb-4' : 'pb-2'
                    } cursor-pointer`}
                    onClick={() => {
                      setOlympiadSliced((prev) => !prev);
                    }}
                  >
                    <div
                      className={`flex ${
                        olympiadSliced ? 'flex-col' : 'flex-col-reverse'
                      } text-gray-600`}
                    >
                      {olympiadSliced ? (
                        <p>{t('stats.see_more')}</p>
                      ) : (
                        <p>{t('performance.hide')}</p>
                      )}
                      {olympiadSliced ? (
                        <ChevronDownIcon className='h-4 -mt-1' />
                      ) : (
                        <ChevronUpIcon className='h-4 -mb-1' />
                      )}
                    </div>
                  </div>
                  {olympiadSliced && (
                    <div className='h-5 w-full opacity-10 bg-gradient-to-b from-white to-gray-500 rounded-b-lg'></div>
                  )}
                </div>
              )}
            </div>
          ) : undefined}
          {data?.data?.exam_ratings?.UNT?.length ? (
            <div className='border rounded-lg'>
              <div className='flex flex-col gap-4 p-4'>
                <div className='text-xl font-semibold'>
                  {t('performance.UNT')}
                </div>
                <div className='flex flex-col gap-4'>
                  {data?.data?.exam_ratings?.UNT?.slice(
                    0,
                    untSliced ? 2 : data?.data?.exam_ratings?.UNT?.length,
                  )?.map((er) => (
                    <div
                      key={Math.random()}
                      className='flex flex-col border rounded-lg py-4 px-6 shadow-md'
                    >
                      <p className='font-medium text-lg'>
                        {er?.exam_name}
                        {'\u00A0\u00A0'}
                        <span className='text-gray-500 font-normal'>
                          {er?.date}
                        </span>
                      </p>
                      <div className='flex lg:flex-row md:flex-row flex-col items-center gap-8'>
                        <div className='rounded-lg w-1/3'>
                          <ReactApexChart
                            type='radialBar'
                            series={[
                              er?.total_question
                                ? (
                                    (er?.total_overall / er?.total_question) *
                                    100
                                  ).toFixed(0)
                                : 0,
                            ]}
                            options={{
                              chart: {
                                height: 350,
                                type: 'radialBar',
                              },
                              stroke: {
                                lineCap: 'round',
                              },
                              fill: {
                                colors: getPercentageColorForApex(
                                  er?.total_overall,
                                ),
                              },
                              plotOptions: {
                                radialBar: {
                                  hollow: {
                                    size: '70%',
                                  },
                                  dataLabels: {
                                    name: {
                                      fontSize: '20px',
                                      color: '#2C59F8',
                                    },
                                    value: {
                                      fontSize: '16px',
                                      color: '#717C9E',
                                    },
                                  },
                                },
                              },
                              labels: [
                                `${er?.total_overall}/${er?.total_question}`,
                              ],
                            }}
                          />
                        </div>
                        <div className='w-full grid grid-cols-2 gap-y-2 gap-x-4 text-sm'>
                          {er?.subjects?.map((s) => (
                            <div
                              key={s?.subject_name}
                              className='flex flex-col gap-1'
                            >
                              <div className='flex flex-row justify-between'>
                                <div>{s?.subject_name}</div>
                                <div>{`${s?.total_correct}/${s?.total_question}`}</div>
                              </div>
                              <div className='w-full relative rounded-lg h-4 bg-gray-200'>
                                <div
                                  className='absolute h-4 rounded-lg text-white 
                              flex flex-row items-center px-2 text-xs'
                                  style={{
                                    width: `${
                                      (s?.total_correct / s?.total_question > 1
                                        ? 1
                                        : s?.total_correct /
                                          s?.total_question) * 100
                                    }%`,
                                    backgroundColor: getPercentageColorForApex(
                                      s?.total_correct / s?.total_question,
                                    ),
                                  }}
                                >
                                  {s?.total_correct &&
                                  s?.total_correct / s?.total_question > 0.19
                                    ? `${(
                                        (s?.total_correct / s?.total_question) *
                                        100
                                      ).toFixed(0)}%`
                                    : ''}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {data?.data?.exam_ratings?.UNT?.length > 2 && (
                <div>
                  <div
                    className={`flex flex-row justify-center px-4 -mt-2 ${
                      untSliced ? ' -mb-4' : 'pb-2'
                    } cursor-pointer`}
                    onClick={() => {
                      setUntSliced((prev) => !prev);
                    }}
                  >
                    <div
                      className={`flex ${
                        untSliced ? 'flex-col' : 'flex-col-reverse'
                      } text-gray-600`}
                    >
                      {untSliced ? (
                        <p>{t('stats.see_more')}</p>
                      ) : (
                        <p>{t('performance.hide')}</p>
                      )}
                      {untSliced ? (
                        <ChevronDownIcon className='h-4 -mt-1' />
                      ) : (
                        <ChevronUpIcon className='h-4 -mb-1' />
                      )}
                    </div>
                  </div>
                  {untSliced && (
                    <div className='h-5 w-full opacity-10 bg-gradient-to-b from-white to-gray-500 rounded-b-lg'></div>
                  )}
                </div>
              )}
            </div>
          ) : undefined}

          {data?.data?.exam_ratings?.KBO?.length ? (
            <div className='border rounded-lg'>
              <div className='flex flex-col gap-4 p-4'>
                <div className='text-xl font-semibold'>
                  {t('performance.KBO')}
                </div>
                <div className='flex flex-col gap-4'>
                  {data?.data?.exam_ratings?.KBO?.slice(
                    0,
                    kboSliced ? 2 : data?.data?.exam_ratings?.KBO?.length,
                  )?.map((er) => (
                    <div
                      key={Math.random()}
                      className='flex flex-col border rounded-lg py-4 px-6 shadow-md'
                    >
                      <p className='font-medium text-lg'>
                        {er?.exam_name}
                        {'\u00A0\u00A0'}
                        <span className='text-gray-500 font-normal'>
                          {er?.date}
                        </span>
                      </p>
                      <div className='flex lg:flex-row md:flex-row flex-col items-center gap-8'>
                        <div className='rounded-lg w-1/3'>
                          <ReactApexChart
                            type='radialBar'
                            series={[
                              er?.total_overall
                                ? (er?.total_overall * 100).toFixed(0)
                                : 0,
                            ]}
                            options={{
                              chart: {
                                height: 350,
                                type: 'radialBar',
                              },
                              stroke: {
                                lineCap: 'round',
                              },
                              fill: {
                                colors: getPercentageColorForApex(
                                  er?.total_overall,
                                ),
                              },
                              plotOptions: {
                                radialBar: {
                                  hollow: {
                                    size: '70%',
                                  },
                                  dataLabels: {
                                    name: {
                                      fontSize: '20px',
                                      color: '#2C59F8',
                                    },
                                    value: {
                                      fontSize: '16px',
                                      color: '#717C9E',
                                    },
                                  },
                                },
                              },
                              labels: [
                                `${er?.total_correct}/${er?.total_question}`,
                              ],
                            }}
                          />
                        </div>
                        <div className='w-full flex flex-col gap-4'>
                          <div className='flex flex-row justify-between'>
                            <div className='flex flex-col'>
                              <div>{`${t('performance.rating_at_school')}: ${
                                er?.school_order
                              }`}</div>
                              {er?.absolute_order < 51 && (
                                <div>{`${t('performance.rating_in_country')}: ${
                                  er?.absolute_order
                                }`}</div>
                              )}
                            </div>
                            <div>
                              {er?.medal === 'bronze' ? (
                                <BronzeMedal className='h-10 w-10' />
                              ) : er?.medal === 'silver' ? (
                                <SilverMedal className='h-10 w-10' />
                              ) : er?.medal === 'gold' ? (
                                <GoldMedal className='h-10 w-10' />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          {er?.subjects?.map((s) => (
                            <div
                              key={s?.subject_name}
                              className='flex flex-col gap-1'
                            >
                              <div className='flex flex-row justify-between'>
                                <div>{s?.subject_name}</div>
                                <div>{`${s?.total_correct}/${s?.total_question}`}</div>
                              </div>
                              <div className='w-full relative rounded-lg h-4 bg-gray-200'>
                                <div
                                  className='absolute h-4 rounded-lg text-white 
                              flex flex-row items-center px-2 text-xs'
                                  style={{
                                    width: `${
                                      (s?.total_correct / s?.total_question) *
                                      100
                                    }%`,
                                    backgroundColor: getPercentageColorForApex(
                                      s?.total_correct / s?.total_question,
                                    ),
                                  }}
                                >
                                  {s?.total_correct &&
                                  s?.total_correct / s?.total_question > 0.19
                                    ? `${(
                                        (s?.total_correct / s?.total_question) *
                                        100
                                      ).toFixed(0)}%`
                                    : ''}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {data?.data?.exam_ratings?.KBO?.length > 2 && (
                <div>
                  <div
                    className={`flex flex-row justify-center px-4 -mt-2 ${
                      kboSliced ? ' -mb-4' : 'pb-2'
                    } cursor-pointer`}
                    onClick={() => {
                      setKboSliced((prev) => !prev);
                    }}
                  >
                    <div
                      className={`flex ${
                        kboSliced ? 'flex-col' : 'flex-col-reverse'
                      } text-gray-600`}
                    >
                      {kboSliced ? (
                        <p>{t('stats.see_more')}</p>
                      ) : (
                        <p>{t('performance.hide')}</p>
                      )}
                      {kboSliced ? (
                        <ChevronDownIcon className='h-4 -mt-1' />
                      ) : (
                        <ChevronUpIcon className='h-4 -mb-1' />
                      )}
                    </div>
                  </div>
                  {kboSliced && (
                    <div className='h-5 w-full opacity-10 bg-gradient-to-b from-white to-gray-500 rounded-b-lg'></div>
                  )}
                </div>
              )}
            </div>
          ) : undefined}

          {data?.data?.exam_ratings?.BTS?.length ? (
            <div className='border rounded-lg'>
              <div className='flex flex-col gap-4 p-4'>
                <div className='text-xl font-semibold'>
                  {t('performance.BTS')}
                </div>
                <div className='flex flex-col gap-4'>
                  {data?.data?.exam_ratings?.BTS?.slice(
                    0,
                    btsSliced ? 2 : data?.data?.exam_ratings?.BTS?.length,
                  )?.map((er) => (
                    <div
                      key={Math.random()}
                      className='flex flex-col border rounded-lg py-4 px-6 shadow-md'
                    >
                      <p className='font-medium text-lg'>
                        {er?.exam_name}
                        {'\u00A0\u00A0'}
                        <span className='text-gray-500 font-normal'>
                          {er?.date}
                        </span>
                      </p>
                      <div className='flex lg:flex-row md:flex-row flex-col items-center gap-8'>
                        <div className='rounded-lg w-1/3'>
                          <ReactApexChart
                            type='radialBar'
                            series={[
                              er?.total_overall
                                ? (er?.total_overall * 100).toFixed(0)
                                : 0,
                            ]}
                            options={{
                              chart: {
                                height: 350,
                                type: 'radialBar',
                              },
                              stroke: {
                                lineCap: 'round',
                              },
                              fill: {
                                colors: getPercentageColorForApex(
                                  er?.total_overall,
                                ),
                              },
                              plotOptions: {
                                radialBar: {
                                  hollow: {
                                    size: '70%',
                                  },
                                  dataLabels: {
                                    name: {
                                      fontSize: '20px',
                                      color: '#2C59F8',
                                    },
                                    value: {
                                      fontSize: '16px',
                                      color: '#717C9E',
                                    },
                                  },
                                },
                              },
                              labels: [
                                `${er?.total_correct}/${er?.total_question}`,
                              ],
                            }}
                          />
                        </div>
                        <div className='w-full grid grid-cols-2 gap-y-2 gap-x-4 text-sm'>
                          {er?.subjects?.map((s) => (
                            <div
                              key={s?.subject_name}
                              className='flex flex-col gap-1'
                            >
                              <div className='flex flex-row justify-between'>
                                <div>{s?.subject_name}</div>
                                <div>{`${s?.total_correct}/${s?.total_question}`}</div>
                              </div>
                              <div className='w-full relative rounded-lg h-4 bg-gray-200'>
                                <div
                                  className='absolute h-4 rounded-lg text-white 
                              flex flex-row items-center px-2 text-xs'
                                  style={{
                                    width: `${
                                      (s?.total_correct / s?.total_question) *
                                      100
                                    }%`,
                                    backgroundColor: getPercentageColorForApex(
                                      s?.total_correct / s?.total_question,
                                    ),
                                  }}
                                >
                                  {s?.total_correct &&
                                  s?.total_correct / s?.total_question > 0.19
                                    ? `${(
                                        (s?.total_correct / s?.total_question) *
                                        100
                                      ).toFixed(0)}%`
                                    : ''}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {data?.data?.exam_ratings?.BTS?.length > 2 && (
                <div>
                  <div
                    className={`flex flex-row justify-center px-4 -mt-2 ${
                      btsSliced ? ' -mb-4' : 'pb-2'
                    } cursor-pointer`}
                    onClick={() => {
                      setBtsSliced((prev) => !prev);
                    }}
                  >
                    <div
                      className={`flex ${
                        btsSliced ? 'flex-col' : 'flex-col-reverse'
                      } text-gray-600`}
                    >
                      {btsSliced ? (
                        <p>{t('stats.see_more')}</p>
                      ) : (
                        <p>{t('performance.hide')}</p>
                      )}
                      {btsSliced ? (
                        <ChevronDownIcon className='h-4 -mt-1' />
                      ) : (
                        <ChevronUpIcon className='h-4 -mb-1' />
                      )}
                    </div>
                  </div>
                  {btsSliced && (
                    <div className='h-5 w-full opacity-10 bg-gradient-to-b from-white to-gray-500 rounded-b-lg'></div>
                  )}
                </div>
              )}
            </div>
          ) : undefined}
        </div>
      ) : (
        <div className='w-full flex flex-row justify-center py-6 text-gray-400 text-lg'>
          No content
        </div>
      )}
    </div>
  );
}
