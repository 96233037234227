import React from 'react';

const MyRoadmapIcon = ({ className }) => {
  return (
    <svg
      className={className ? className : 'h-5'}
      viewBox='0 0 24 24'
      fill='current'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25 13H18.75C19.9926 13 21 14.0074 21 15.25V18.75C21 19.9926 19.9926 21 18.75 21H5.25C4.00736 21 3 19.9926 3 18.75V15.25C3 14.0074 4.00736 13 5.25 13ZM18.75 19.5C19.162 19.4946 19.4946 19.162 19.5 18.75V15.25C19.4946 14.838 19.162 14.5054 18.75 14.5H5.25C4.83804 14.5054 4.50539 14.838 4.5 15.25V18.75C4.50539 19.162 4.83804 19.4946 5.25 19.5H18.75Z'
        fill='current'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.25 3H18.75C19.9926 3 21 4.00736 21 5.25V8.75C21 9.99264 19.9926 11 18.75 11H15.25C14.0074 11 13 9.99264 13 8.75V5.25C13 4.00736 14.0074 3 15.25 3ZM18.75 9.5C19.162 9.49461 19.4946 9.16196 19.5 8.75V5.25C19.4946 4.83804 19.162 4.50539 18.75 4.5H15.25C14.838 4.50539 14.5054 4.83804 14.5 5.25V8.75C14.5054 9.16196 14.838 9.49461 15.25 9.5H18.75Z'
        fill='current'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.75 11H5.25C4.00736 11 3 9.99264 3 8.75V5.25C3 4.00736 4.00736 3 5.25 3H8.75C9.99264 3 11 4.00736 11 5.25V8.75C11 9.99264 9.99264 11 8.75 11ZM5.25 4.5C4.83804 4.50539 4.50539 4.83804 4.5 5.25V8.75C4.50539 9.16196 4.83804 9.49461 5.25 9.5H8.75C9.16196 9.49461 9.49461 9.16196 9.5 8.75V5.25C9.49461 4.83804 9.16196 4.50539 8.75 4.5H5.25Z'
        fill='current'
      />
    </svg>
  );
};
export default MyRoadmapIcon;
