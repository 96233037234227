import React from 'react';
import { replaceHtmlEntities } from '../../../utils/helpers/helpers';

const Campus = ({ info }) => {
  return (
    <div className='flex flex-col gap-12 border rounded-lg p-4 lg:px-36 md:px-24'>
      {info?.campusInformation?.map((ci) => (
        <div key={ci?.id} className='flex flex-col gap-2'>
          <div className='text-2xl font-semibold'>{ci?.title}</div>
          <div className='flex flex-row gap-8 items-center'>
            <div className='w-1/2'>
              {replaceHtmlEntities(
                ci?.description?.replace(/(<([^>]+)>)/gi, ''),
              )}
            </div>
            {ci?.image && (
              <div className='w-1/2'>
                <img
                  src={`https://unitap.org/nova/files/${ci?.image}`}
                  alt='img'
                  className='object-cover w-full h-72 rounded-xl object-bottom'
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Campus;
