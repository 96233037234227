import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoadmapTasks } from '../../services/roadmapTask/roadmapTaskService';
import { getTaskDomains } from '../../services/taskDomains/statsService';
import Add from './BREAD/Add';
import Table from './Table';
import Timeline from './Timeline';
import { Button } from '../../components/forms/Buttons/Button';
import { CheckIcon } from '@heroicons/react/24/outline';
import { UserContext } from '../../utils/contexts/UserContext';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import Description from './Description';

const PAGE_SIZE = 100;

const Roadmap = ({ studentUuid }) => {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const [domains, setDomains] = useState([]);
  const [tasks, setTasks] = useState([]);
  // eslint-disable-next-line
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [isTableMode, setIsTableMode] = useState(true);

  useEffect(() => {
    getTaskDomains({}).then((res) => {
      setDomains(res?.results);
    });
  }, []);

  const getInitialTasks = () => {
    setLoadingTasks(true);
    getRoadmapTasks({
      student: studentUuid ?? user?.uuid,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setTasks(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoadingTasks(false));
  };

  const getTasks = () => {
    getRoadmapTasks({
      student: studentUuid ?? user?.uuid,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setTasks(res?.results);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (user || studentUuid) {
      getInitialTasks();
    }
    // eslint-disable-next-line
  }, [user, studentUuid]);

  return (
    <div className='flex flex-col gap-4 p-4'>
      {/* <Description /> */}
      <div className='flex flex-row justify-between'>
        <Add
          domains={domains}
          refreshData={getTasks}
          studentUuid={studentUuid}
        />
        <div className='flex flex-row gap-2'>
          <Button
            Icon={isTableMode && CheckIcon}
            color={isTableMode && 'primary'}
            text={'Table'}
            className='w-32'
            onClick={() => setIsTableMode((e) => !e)}
          />
          <Button
            Icon={!isTableMode && CheckIcon}
            color={!isTableMode && 'primary'}
            text={'Timeline'}
            className='w-32'
            onClick={() => setIsTableMode((e) => !e)}
          />
        </div>
      </div>
      <div className='flex flex-row gap-8'>
        <div className='flex flex-row gap-2 items-center'>
          <div className='h-6 w-6 rounded-md bg-success'></div>
          <p>{`${t('roadmap.academic_domain')}`}</p>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div className='h-6 w-6 rounded-md bg-amethyst'></div>
          <p>{`${t('roadmap.career_domain')}`}</p>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div className='h-6 w-6 rounded-md bg-primary'></div>
          <p>{`${t('roadmap.social_domain')}`}</p>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div className='h-6 w-6 rounded-md bg-error'></div>
          <p>{`${t('roadmap.unt domain')}`}</p>
        </div>
      </div>
      {loadingTasks ? (
        <div className='flex flex-row justify-center pt-12'>
          <SimpleLoader className={'w-10 h-10'} />
        </div>
      ) : tasks?.length ? (
        isTableMode ? (
          <Table
            data={tasks}
            domains={domains}
            refreshData={getTasks}
            studentUuid={studentUuid}
          />
        ) : (
          <Timeline
            data={tasks}
            domains={domains}
            refreshData={getInitialTasks}
            studentUuid={studentUuid}
          />
        )
      ) : (
        <div
          className='flex flex-row justify-center py-8 mt-4 text-gray-400 text-lg
          border rounded-lg'
        >
          No content
        </div>
      )}
    </div>
  );
};

export default Roadmap;
