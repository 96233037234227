import React from 'react';

const Checkbox = ({ key, label, checked, onChange, disabled }) => {
  return (
    <div
      key={key}
      className='flex flex-row items-start group'
      onClick={onChange}
    >
      <input
        className='h-4 w-4 rounded-md bg-white 
            focus:outline-none
            transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer'
        id='checkbox'
        type='checkbox'
        // onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <label className='inline-block group-hover:text-primary cursor-pointer'>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
