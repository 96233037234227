import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/forms/Buttons/Button';

export default function CardBase({ title, img, text, link, state }) {
  const navigate = useNavigate();

  return (
    <div className='max-w-lg rounded-xl overflow-hidden shadow-md flex flex-col justify-between'>
      <div>
        <img className='w-full p-2 rounded' src={img} alt='cap' />
        <div className='px-6 py-4 overflow-hidden'>
          <div className='font-bold text-2xl mb-2 whitespace-pre-line'>
            {title}
          </div>
          <p className='text-base min-h-fit whitespace-pre-line'>{text}</p>
        </div>
      </div>

      <div>
        <div className='px-6 py-2'>
          <Button
            text={'Explore'}
            onClick={() => {
              navigate(link, { state });
            }}
          />
        </div>
        {/* <button className='bg-blue-600 text-white py-2 px-8 rounded focus:outline-none focus:shadow-outline'>
          Explore
        </button> */}
      </div>
    </div>
  );
}
