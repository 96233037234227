import { PlusCircleIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/InputBil';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { toast } from 'react-toastify';
import DatePicker from '../../../components/forms/DatePicker/DatePicker';
import { format } from 'date-fns';
import { postRoadmapSubtask } from '../../../services/roadmapTask/roadmapSubtaskService';
import { UserContext } from '../../../utils/contexts/UserContext';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';
import SidebarModal from '../../../components/modals/SidebarModal/SidebarModal';

const AddSub = ({ task, refreshData, studentUuid }) => {
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const reset = () => {
    setTitle('');
    setDescription('');
    setLink('');
    setStartDate(null);
    setEndDate(null);
  };

  const onClose = () => {
    reset();
    setId('');
  };

  useEffect(() => {
    if (id !== uuid) {
      reset();
    }
  }, [id, uuid]);

  return (
    <>
      <PlusCircleIcon
        className='h-5 text-primary cursor-pointer'
        onClick={() => {
          setId(uuid);
        }}
      />

      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.create_new_task')}
        HeaderIcon={PlusSmallIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            required
          />
          <TextArea
            label={t('projects.description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Input
            label={t('roadmap.link')}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            value={link}
          />
          <div className='flex flex-row gap-4'>
            <DatePicker
              date={startDate}
              setDate={(d) => {
                // if (endDate < d && endDate) {
                //   toast.error(t('roadmap.reversed_date_error'));
                //   return;
                // }
                setStartDate(d);
              }}
              label={t('roadmap.start_date')}
              required
            />
            <DatePicker
              date={endDate}
              setDate={(d) => {
                // if (startDate > d && startDate) {
                //   toast.error(t('roadmap.reversed_date_error'));
                //   return;
                // }
                setEndDate(d);
              }}
              label={t('roadmap.end_date')}
              required
            />
          </div>
          <div className='flex flex-row justify-center mt-4'>
            <Button
              text={t('roadmap.add')}
              loading={loadingSubmit}
              onClick={() => {
                if (!title || !startDate || !endDate) {
                  toast.error(t('roadmap.required_fields_empty'));
                  return;
                }

                if (startDate > endDate) {
                  toast.error(t('roadmap.reversed_date_error'));
                  return;
                }

                const body = new FormData();
                body.append('name', title);
                body.append('description', description);
                body.append('parent', task);
                body.append('link', link);
                body.append('start_date', format(startDate, 'yyyy-MM-dd'));
                body.append('deadline', format(endDate, 'yyyy-MM-dd'));
                body.append('student', studentUuid ?? user?.uuid);
                body.append('assignor', user?.uuid);

                setLoadingSubmit(true);
                postRoadmapSubtask(body)
                  .then(() => {
                    toast.success(t('roadmap.added_successfully'));
                    refreshData();
                    onClose();
                  })
                  .catch((err) => {
                    toast.error(
                      err?.response?.data?.link?.length
                        ? err?.response?.data?.link[0]
                        : t('roadmap.something_went_wrong'),
                    );
                  })
                  .finally(() => setLoadingSubmit(false));
              }}
            />
          </div>
        </div>
      </SidebarModal>
    </>
  );
};

export default AddSub;
