import React, { useRef } from 'react';
import blueHeaderImg from '../../assets/images/project_set/Frame1860.svg';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useEffect } from 'react';

const Description = () => {
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    if (open) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setHeight('0px');
    }
  }, [open]);

  return (
    <div className='flex flex-col text-white'>
      <div
        id='bluediv'
        className='flex flex-col gap-8 items-center justify-center px-8 pt-12 pb-4 rounded-xl
            4xl:bg-[length:50%] xl:bg-[length:55%] bg-[length:80%]
            transition-all ease-in duration-500 cursor-pointer'
        style={{
          backgroundImage: `url(${blueHeaderImg})`,
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#2F80ED',
        }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div className='flex flex-row justify-center items-center gap-8'>
          <div className='text-3xl font-bold'>
            Unlock your learning potential with Project set!
          </div>
          {open ? (
            <ChevronUpIcon className='h-8' />
          ) : (
            <ChevronDownIcon className='h-8' />
          )}
        </div>
        <div
          ref={contentRef}
          className='flex flex-col gap-4 text-lg lg:px-24 md:px-20 px-4 transition-all duration-500 ease-in-out overflow-hidden'
          style={{ height: height }}
        >
          <div>
            <span className='font-semibold'>Project set</span> is your curated
            collection of chapters and projects, organized by school subjects
            and tailored to your grade level. It's designed to give you a
            hands-on, practical learning experience that aligns perfectly with
            your curriculum.
          </div>
          <div className='flex flex-col'>
            <p className='font-semibold'>Why Use Project set?</p>
            <div className='whitespace-pre-line'>
              {`- Each project set is carefully crafted to match your grade level and school subjects, ensuring you’re always working on what’s relevant.
- Move beyond textbooks and lectures—use project set to dive into real-world projects that let you apply what you’ve learned in practical, meaningful ways.
- Got your own ideas? Customize your learning experience by creating your own project set. Tailor your projects to your interests and educational goals.`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
